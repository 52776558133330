import classNames from 'classnames';
import { Children } from 'react';
import { useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ObjectType } from '../../api';
import { AnswerCard, Tool, WordCard } from '../../components';
import { IndicatorType, Outcome, Phoneme, answersState, definitionState, dictionaryState, emptyPhoneme, indicatorsState, offsetsState, outcomeState, phonemesState, useSetDefinition, useSetPhoneme } from '../../state';
import { Home, Scissors } from '../../svg';
import './Game.scss';

export const Game = () => {
    const navigate = useNavigate();

    const dictionary = useRecoilValue(dictionaryState);
    const definition = useRecoilValue(definitionState);
    const phonemes = useRecoilValue(phonemesState);
    const answers = useRecoilValue(answersState);
    const outcome = useRecoilValue(outcomeState);
    const offsets = useRecoilValue(offsetsState);
    const indicators = useRecoilValue(indicatorsState);

    const setDefinition = useSetDefinition();
    const setPhoneme = useSetPhoneme();

    const outcomeClasses = classNames("Outcome HoverEffect", { Correct: outcome === Outcome.Correct, Incorrect: outcome === Outcome.Incorrect, Incomplete: outcome === Outcome.Incomplete });

    const getMessage = () => {
        if (outcome === Outcome.Incomplete) {
            return "Keep going";
        }

        if (outcome === Outcome.Correct) {
            return (<div>You spelt <span className='WordAnswer'>{definition.word}</span>! Try another word</div>);
        }

        return "Not quite!";
    }

    function clickOutcome(event: any) {
        if (outcome !== Outcome.Correct) {
            return;
        }

        setDefinition(dictionary.nextWord());
    }

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: [ObjectType.AnswerCard],
            drop: (item: { phoneme: Phoneme }, monitor) => {
                // Check if the drop has already been handled by another drop target (e.g. AnswerCard)
                if (monitor.didDrop()) {
                    return undefined;
                }

                setPhoneme(item.phoneme);

                return { phoneme: emptyPhoneme };
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver({ shallow: true })
            }),
        }),
        []
    );

    const appClassName = classNames('Game', { IsOver: isOver });

    return (
        <div ref={dropRef} className={appClassName}>
            <div className="Toolbox" style={{ justifyContent: 'start' }}>
                <Home className='HoverEffect' title='Home' cursor="pointer" onClick={() => navigate("/")} />
            </div>
            <div className="CardContainer">
                <div className="PhonemePanel">
                    {Children.toArray(phonemes.map((phoneme, index) => (
                        <div className="Phonemes" style={{ marginTop: offsets[index].top, marginLeft: offsets[index].left }}>
                            {Children.toArray(phoneme.map(component => <WordCard phoneme={component} index={index} />))}
                        </div>
                    )))}
                </div>
                <div className="AnswerPanel">
                    <div className='Answers'>
                        {Children.toArray(answers.map((phoneme, index) => (
                            <AnswerCard phoneme={phoneme} index={index} />
                        )))}
                    </div>
                    <div className='Indicators'>
                        {Children.toArray(indicators.map(indicator => (
                            <div className={classNames("Indicator", { Left: indicator === IndicatorType.Left, Right: indicator === IndicatorType.Right, Between: indicator === IndicatorType.Between })}>
                                <div className='Inner'></div>
                            </div>
                        )))}
                    </div>
                </div>
                <div className="SummaryPanel">
                    <div className={outcomeClasses} onClick={clickOutcome}>
                        <div className="Text">{getMessage()}</div>
                    </div>
                </div>
            </div>
            <div className="Toolbox">
                <Tool type={ObjectType.ScissorsTool}>
                    <Scissors title='Scissors' cursor="grab" transform='scale(-1, -1)' />
                </Tool>
            </div>
        </div>
    );
}
