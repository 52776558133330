
export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array];

    for (let from = shuffledArray.length - 1; from > 0; from--) {
        const to = getRandomInt(from + 1);

        [shuffledArray[from], shuffledArray[to]] = [shuffledArray[to], shuffledArray[from]];
    }

    return shuffledArray;
}

export function checkedShuffleArray<T>(array: T[]): T[] {
    // Special case, where there is no need to shuffle
    if (array.length === 1) {
        return array;
    }

    const shuffled = shuffleArray(array);

    if (arraysEqual(array, shuffled)) {
        return checkedShuffleArray(array);
    }

    return shuffled;
}

export function arraysEqual<T>(array1: T[], array2: T[]) {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true;
}
