export type WordText = string | undefined;

export enum PhonemeType {
    Left,
    Right,
    Grapheme,
    Digraph
}

export interface Phoneme {
    readonly text: WordText;
    readonly type: PhonemeType;
    readonly hasText: boolean;
    readonly components: WordText[];
    readonly inner: WordText[];

    cut(): Phoneme[];
}

export class Grapheme implements Phoneme {
    readonly text: WordText;
    readonly type: PhonemeType;
    readonly inner = [];

    constructor(text: WordText, type: PhonemeType = PhonemeType.Grapheme) {
        this.text = text;
        this.type = type;
    }

    get components(): WordText[] {
        return [this.text];
    }

    get hasText(): boolean {
        return this.text !== undefined;
    }

    cut() {
        return [];
    }
}

// TODO - Add property that captures required inner characters in order
export class Digraph implements Phoneme {
    readonly type = PhonemeType.Digraph;
    readonly hasText = true;
    readonly components: WordText[];
    readonly inner: string[];

    constructor(components: string[], inner: string[]) {
        this.components = components;
        this.inner = inner;
    }

    get text(): WordText {
        return this.components.join("");
    }

    cut(): Phoneme[] {
        const left = new SplitDigraph(this.components[0], PhonemeType.Left, this.inner);
        const right = new SplitDigraph(this.components[1], PhonemeType.Right, this.inner);

        return [left, right];
    }
}

export class SplitDigraph implements Phoneme {
    readonly text: WordText;
    readonly type: PhonemeType;
    readonly hasText = true;
    readonly inner: string[];

    constructor(text: WordText, type: PhonemeType, inner: string[]) {
        this.text = text;
        this.type = type;
        this.inner = inner;
    }

    get components(): WordText[] {
        return [this.text];
    }

    cut() {
        return [];
    }
}

export const emptyPhoneme: Phoneme = {
    text: undefined,
    type: PhonemeType.Grapheme,
    hasText: false,
    components: [],
    inner: [],

    cut() {
        return [];
    }
}

export const isDigraph = (phoneme: Phoneme) => phoneme.type === PhonemeType.Digraph;

export const isSplitDigraph = (phoneme: Phoneme) => phoneme.type === PhonemeType.Left || phoneme.type === PhonemeType.Right;
