import { selector } from "recoil";
import { arraysEqual } from "../Utility";
import { answersState, definitionState } from "./Atoms";
import { PhonemeType, isSplitDigraph } from "./Phonemes";

export enum Outcome {
    Correct,
    Incorrect,
    Incomplete
}

export enum IndicatorType {
    Left,
    Right,
    Between,
    None
}

export const outcomeState = selector({
    key: 'outcome',
    get: ({ get }) => {
        const definition = get(definitionState);
        const answers = get(answersState);
        const indicators = get(indicatorsState);

        // This happens when the application is initialising (since the default definition is undefined)
        if (!definition) {
            return Outcome.Incomplete;
        }

        if (answers.some(answer => !answer.hasText)) {
            return Outcome.Incomplete;
        }

        const word = answers.map(answer => answer.text).join("");
        const splitDigraph = answers.find(isSplitDigraph);

        let validWord = word === definition.word.toLowerCase();

        if (splitDigraph) {
            const between = indicators.map((indicator, index) => indicator === IndicatorType.Between ? index : -1).filter(index => index !== -1).map(index => answers[index].text);
            
            validWord = validWord && between.length > 0 && arraysEqual(between, splitDigraph.inner);
        }

        return validWord ? Outcome.Correct : Outcome.Incorrect;
    },
});

export const indicatorsState = selector<IndicatorType[]>({
    key: 'indicator',
    get: ({ get }) => {
        const answers = get(answersState);

        const leftIndex = answers.findIndex(answer => answer.type === PhonemeType.Left);
        const rightIndex = answers.findIndex(answer => answer.type === PhonemeType.Right);
        const minIndex = Math.min(leftIndex, rightIndex);
        const maxIndex = Math.max(leftIndex, rightIndex);
        const isBetween = (index: number): boolean => index > minIndex && index < maxIndex;
        const hasBoth = leftIndex >= 0 && rightIndex >= 0;

        return answers.map((_, index) => {
            if (!hasBoth) { return IndicatorType.None; }
            if (index === minIndex) { return IndicatorType.Left; }
            if (index === maxIndex) { return IndicatorType.Right; }
            if (isBetween(index)) { return IndicatorType.Between; }

            return IndicatorType.None;
        });
    },
});
