import { atom, useSetRecoilState } from "recoil";
import { cardSize, maxCardGap, wordPanelHeight } from "../Constants";
import { checkedShuffleArray } from "../Utility";
import { Dictionary, WordDefinition } from "../api";
import { Digraph, Grapheme, Phoneme, emptyPhoneme, isSplitDigraph } from "./Phonemes";

export interface CardOffset {
    top: number | string;
    left: number | string;
}

const resetAnswers = (phonemes: Phoneme[][]) => phonemes.flatMap(phoneme => phoneme.flatMap(entry => entry.components.map(_ => emptyPhoneme)));

const processPhonemes = (definition: WordDefinition): Phoneme[] => {
    const phonemes = new Array<Phoneme>();

    for (let phoneme of definition.phonemes) {
        const matches = phoneme.match(/(.+)\[(.*?)\](.+)/);

        // Regular phoneme definition
        if (!matches) {
            phonemes.push(new Grapheme(phoneme));

            continue;
        }

        // Split digraph definition
        const outside = [matches[1], matches[3]];
        const inside = matches[2].split(",");

        // Add the split digraph definition
        phonemes.push(new Digraph(outside, inside));

        // Add a definition for each of the phonemes defined inside the digraph
        inside.forEach(phoneme => phonemes.push(new Grapheme(phoneme)));
    }

    return phonemes;
}

const calculateOffsets = (phonemes: Phoneme[]): CardOffset[] => {
    return phonemes.map((_, index) => ({
        top: `${Math.random() * (wordPanelHeight - cardSize)}vh`,
        left: index === 0 ? 0 : `${Math.random() * maxCardGap}vh`
    }));
};

export const dictionaryState = atom<Dictionary>({
    key: 'dictionary',
    default: undefined,
    dangerouslyAllowMutability: true
});

export const definitionState = atom<WordDefinition>({
    key: 'definition',
    default: undefined
});

export const phonemesState = atom<Phoneme[][]>({
    key: 'phonemes',
    default: []
});

export const answersState = atom<Phoneme[]>({
    key: 'answers',
    default: []
});

export const offsetsState = atom<CardOffset[]>({
    key: 'offset',
    default: []
});

export function useSetDefinition(): (definition: WordDefinition) => void {
    const setDefinition = useSetRecoilState(definitionState);
    const setPhonemes = useSetRecoilState(phonemesState);
    const setAnswers = useSetRecoilState(answersState);
    const setOffsets = useSetRecoilState(offsetsState);

    return (definition: WordDefinition) => {
        const processedPhonemes = processPhonemes(definition);
        const phonemes = checkedShuffleArray(processedPhonemes).map(phoneme => [phoneme]);

        setDefinition(definition);
        setPhonemes(phonemes);
        setAnswers(resetAnswers(phonemes));
        setOffsets(calculateOffsets(processedPhonemes));
    };
}

export function useSetPhoneme(): (phoneme: Phoneme) => void {
    const setPhonemes = useSetRecoilState(phonemesState);

    return (phoneme: Phoneme) => setPhonemes(current => {
        // By default, no card slot is available to place the phoneme
        let index = -1;

        // If dropping a split digraph, try and pair it with any other split digraph parts
        if (isSplitDigraph(phoneme)) {
            index = current.findIndex(v => v.some(c => isSplitDigraph(c)));
        }

        // Otherwise look for an empty card slot
        if (index === -1) {
            index = current.findIndex(v => v.length === 0);
        }

        return current.map((v, i) => index === i ? v.concat(phoneme).sort((a, b) => a.type - b.type) : v);
    });
}

export function useSetAnswer(): (answer: Phoneme, index: number) => void {
    const setAnswers = useSetRecoilState(answersState);

    return (answer: Phoneme, index: number) => setAnswers(current => current.map((v, i) => index === i ? answer : v));
}