import classNames from 'classnames';
import { Children, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFocusSet } from '../../api';
import { ReactComponent as Forward } from '../../svg/forward.svg';
import './FocusMenu.scss';

export interface FocusMenuProps { }

export const allButtonText = "All words";

export const FocusMenu: FC<FocusMenuProps> = () => {
    const { stage } = useParams();
    const focusSet = useFocusSet(stage);
    const navigate = useNavigate();

    if (focusSet.length > 1) {
        focusSet.unshift(allButtonText);
    }

    function handleClick(focus: string) {
        navigate(`/${stage}/${encodeURIComponent(focus)}`);
    }

    return (
        <div className="FocusMenu">
            <div className="FocusTitle">
                <Forward transform='scale(-1, 1)' className='Icon' title='Change Stage' cursor="pointer" onClick={() => navigate("/")} />
                <div className='Text'>{stage}</div>
            </div>
            <div className="FocusNames">
                {Children.toArray(focusSet.map(focus => {
                    const focusClassName = classNames("FocusName HoverEffect", { All: focus === allButtonText });

                    return (
                        <div className={focusClassName} onClick={() => handleClick(focus)}>
                            <div className="Text">{focus}</div>
                        </div>
                    )
                }))}
            </div>
        </div>
    )
};