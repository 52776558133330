//Word,Stage,Focus,Phoneme/Syllable
// Replace:
// (\w+),([\d\.]+),(.+?),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),([\w\[\]]+),*
// with:
// { word: "$1", stage: $2, focus: "$3", phonemes: ["$4", "$5", "$6", "$7", "$8", "$9", "$10", "$11", "$12", "$13", "$14", "$15", "$16", "$17"] },
export interface WordDefinition {
    word: string;
    stage: string;
    focus: string;
    phonemes: string[];
}

export const Words: WordDefinition[] = [
    { word: "pray", stage: "Stage 1", focus: "ay", phonemes: ["p", "r", "ay"] },
    { word: "tray", stage: "Stage 1", focus: "ay", phonemes: ["t", "r", "ay"] },
    { word: "stay", stage: "Stage 1", focus: "ay", phonemes: ["s", "t", "ay"] },
    { word: "May", stage: "Stage 1", focus: "ay", phonemes: ["M", "ay"] },
    { word: "squeak", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "ea", "k"] },
    { word: "squid", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "i", "d"] },
    { word: "squint", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "i", "n", "t"] },
    { word: "squeeze", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "ee", "ze"] },
    { word: "squelch", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "e", "l", "ch"] },
    { word: "squish", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "i", "sh"] },
    { word: "squad", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "a", "d"] },
    { word: "square", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "are"] },
    { word: "squawks", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "aw", "k"] },
    { word: "squat", stage: "Stage 2", focus: "qu as kw", phonemes: ["s", "qu", "a", "t"] },
    { word: "lawn", stage: "Stage 3", focus: "aw", phonemes: ["l", "aw", "n"] },
    { word: "hawk", stage: "Stage 3", focus: "aw", phonemes: ["h", "aw", "k"] },
    { word: "drawn", stage: "Stage 3", focus: "aw", phonemes: ["d", "r", "aw", "n"] },
    { word: "claw", stage: "Stage 3", focus: "aw", phonemes: ["c", "l", "aw"] },
    { word: "draw", stage: "Stage 3", focus: "aw", phonemes: ["d", "r", "aw"] },
    { word: "straw", stage: "Stage 3", focus: "aw", phonemes: ["s", "t", "r", "aw"] },
    { word: "gawk", stage: "Stage 3", focus: "aw", phonemes: ["g", "aw", "k"] },
    { word: "yawn", stage: "Stage 3", focus: "aw", phonemes: ["y", "aw", "n"] },
    { word: "crawl", stage: "Stage 3", focus: "aw", phonemes: ["c", "r", "aw", "l"] },
    { word: "flaw", stage: "Stage 3", focus: "aw", phonemes: ["f", "l", "aw"] },
    { word: "actor", stage: "Stage 4", focus: "or as schwa", phonemes: ["a", "c", "t", "or"] },
    { word: "terror", stage: "Stage 4", focus: "or as schwa", phonemes: ["t", "e", "rr", "or"] },
    { word: "tremor", stage: "Stage 4", focus: "or as schwa", phonemes: ["t", "r", "e", "m", "or"] },
    { word: "equator", stage: "Stage 4", focus: "or as schwa", phonemes: ["e", "qu", "a", "t", "or"] },
    { word: "spectator", stage: "Stage 4", focus: "or as schwa", phonemes: ["s", "p", "e", "c", "ta", "t", "or"] },
    { word: "emperor", stage: "Stage 4", focus: "or as schwa", phonemes: ["em", "per", "or"] },
    { word: "elevator", stage: "Stage 4", focus: "or as schwa", phonemes: ["el", "e", "va", "t", "or"] },
    { word: "visitor", stage: "Stage 4", focus: "or as schwa", phonemes: ["v", "i", "s", "i", "t", "or"] },
    { word: "dictator", stage: "Stage 4", focus: "or as schwa", phonemes: ["d", "i", "c", "t", "a", "t", "or"] },
    { word: "donor", stage: "Stage 4", focus: "or as schwa", phonemes: ["d", "o", "n", "or"] },
    { word: "fork", stage: "Stage 2", focus: "or", phonemes: ["f", "or", "k"] },
    { word: "report", stage: "Stage 2", focus: "or", phonemes: ["r", "e", "p", "or", "t"] },
    { word: "torch", stage: "Stage 2", focus: "or", phonemes: ["t", "or", "ch"] },
    { word: "short", stage: "Stage 2", focus: "or", phonemes: ["sh", "or", "t"] },
    { word: "fort", stage: "Stage 2", focus: "or", phonemes: ["f", "or", "t"] },
    { word: "cord", stage: "Stage 2", focus: "or", phonemes: ["c", "or", "d"] },
    { word: "forget", stage: "Stage 2", focus: "or", phonemes: ["f", "or", "g", "e", "t"] },
    { word: "shorn", stage: "Stage 2", focus: "or", phonemes: ["sh", "or", "n"] },
    { word: "worn", stage: "Stage 2", focus: "or", phonemes: ["w", "or", "n"] },
    { word: "storm", stage: "Stage 2", focus: "or", phonemes: ["s", "t", "or", "m"] },
    { word: "made", stage: "Stage 2", focus: "a|e", phonemes: ["m", "a[d]e"] },
    { word: "date", stage: "Stage 2", focus: "a|e", phonemes: ["d", "a[t]e"] },
    { word: "sane", stage: "Stage 2", focus: "a|e", phonemes: ["s", "a[n]e"] },
    { word: "case", stage: "Stage 2", focus: "a|e", phonemes: ["c", "a[s]e"] },
    { word: "taste", stage: "Stage 2", focus: "a|e", phonemes: ["t", "a[s,t]e"] },
    { word: "wave", stage: "Stage 2", focus: "a|e", phonemes: ["w", "a[v]e"] },
    { word: "daze", stage: "Stage 2", focus: "a|e", phonemes: ["d", "a[z]e"] },
    { word: "stake", stage: "Stage 2", focus: "a|e", phonemes: ["s", "t", "a[k]e"] },
    { word: "safe", stage: "Stage 2", focus: "a|e", phonemes: ["s", "a[f]e"] },
    { word: "shade", stage: "Stage 2", focus: "a|e", phonemes: ["sh", "a[d]e"] },
    { word: "trophy", stage: "Stage 3", focus: "ph as f", phonemes: ["t", "r", "o", "ph", "y"] },
    { word: "dolphin", stage: "Stage 3", focus: "ph as f", phonemes: ["d", "o", "l", "ph", "i", "n"] },
    { word: "typhoon", stage: "Stage 3", focus: "ph as f", phonemes: ["t", "y", "ph", "oo", "n"] },
    { word: "pamphlet", stage: "Stage 3", focus: "ph as f", phonemes: ["p", "a", "m", "ph", "l", "e", "t"] },
    { word: "triumph", stage: "Stage 3", focus: "ph as f", phonemes: ["t", "r", "i", "u", "m", "ph"] },
    { word: "phase", stage: "Stage 3", focus: "ph as f", phonemes: ["ph", "a[s]e"] },
    { word: "phantom", stage: "Stage 3", focus: "ph as f", phonemes: ["ph", "a", "n", "t", "o", "m"] },
    { word: "phone", stage: "Stage 3", focus: "ph as f", phonemes: ["ph", "o[n]e"] },
    { word: "phobia", stage: "Stage 3", focus: "ph as f", phonemes: ["ph", "o", "b", "i", "a"] },
    { word: "orphan", stage: "Stage 3", focus: "ph as f", phonemes: ["or", "ph", "a", "n"] },
    { word: "want", stage: "Stage 4", focus: "a as o", phonemes: ["w", "a", "n", "t"] },
    { word: "yacht", stage: "Stage 4", focus: "a as o", phonemes: ["y", "a", "cht"] },
    { word: "squat", stage: "Stage 4", focus: "a as o", phonemes: ["s", "qu", "a", "t"] },
    { word: "watched", stage: "Stage 4", focus: "a as o", phonemes: ["w", "a", "t", "ch", "ed"] },
    { word: "swamp", stage: "Stage 4", focus: "a as o", phonemes: ["s", "w", "a", "m", "p"] },
    { word: "wander", stage: "Stage 4", focus: "a as o", phonemes: ["w", "a", "n", "d", "er"] },
    { word: "squabble", stage: "Stage 4", focus: "a as o", phonemes: ["s", "qu", "a", "bb", "le"] },
    { word: "squadron", stage: "Stage 4", focus: "a as o", phonemes: ["s", "qu", "a", "d", "r", "o", "n"] },
    { word: "quality", stage: "Stage 4", focus: "a as o", phonemes: ["qu", "a", "l", "i", "t", "y"] },
    { word: "wallet", stage: "Stage 4", focus: "a as o", phonemes: ["w", "a", "ll", "e", "t"] },
    { word: "chart", stage: "Stage 2", focus: "ar", phonemes: ["ch", "ar", "t"] },
    { word: "start", stage: "Stage 2", focus: "ar", phonemes: ["s", "t", "ar", "t"] },
    { word: "shark", stage: "Stage 2", focus: "ar", phonemes: ["sh", "ar", "k"] },
    { word: "large", stage: "Stage 2", focus: "ar", phonemes: ["l", "ar", "ge"] },
    { word: "March", stage: "Stage 2", focus: "ar", phonemes: ["M", "ar", "ch"] },
    { word: "spark", stage: "Stage 2", focus: "ar", phonemes: ["s", "p", "ar", "k"] },
    { word: "alarm", stage: "Stage 2", focus: "ar", phonemes: ["a", "l", "ar", "m"] },
    { word: "party", stage: "Stage 2", focus: "ar", phonemes: ["p", "ar", "t", "y"] },
    { word: "market", stage: "Stage 2", focus: "ar", phonemes: ["m", "ar", "k", "et"] },
    { word: "marsh", stage: "Stage 2", focus: "ar", phonemes: ["m", "ar", "sh"] },
    { word: "snarl", stage: "Stage 2", focus: "ar", phonemes: ["s", "n", "ar", "l"] },
    { word: "bird", stage: "Stage 2", focus: "ir", phonemes: ["b", "ir", "d"] },
    { word: "skirt", stage: "Stage 2", focus: "ir", phonemes: ["s", "k", "ir", "t"] },
    { word: "firm", stage: "Stage 2", focus: "ir", phonemes: ["f", "ir", "m"] },
    { word: "thirsty", stage: "Stage 2", focus: "ir", phonemes: ["th", "ir", "s", "t", "y"] },
    { word: "thirty", stage: "Stage 2", focus: "ir", phonemes: ["th", "ir", "t", "y"] },
    { word: "thirteen", stage: "Stage 2", focus: "ir", phonemes: ["th", "ir", "t", "ee", "n"] },
    { word: "shirt", stage: "Stage 2", focus: "ir", phonemes: ["sh", "ir", "t"] },
    { word: "chirp", stage: "Stage 2", focus: "ir", phonemes: ["ch", "ir", "ph"] },
    { word: "first", stage: "Stage 2", focus: "ir", phonemes: ["f", "ir", "s", "t"] },
    { word: "fir", stage: "Stage 2", focus: "ir", phonemes: ["f", "ir"] },
    { word: "girl", stage: "Stage 2", focus: "ir", phonemes: ["g", "ir", "l"] },
    { word: "these", stage: "Stage 3", focus: "e|e", phonemes: ["th", "e[s]e"] },
    { word: "even", stage: "Stage 3", focus: "e|e", phonemes: ["e[v]e", "n"] },
    { word: "here", stage: "Stage 3", focus: "e|e", phonemes: ["h", "e[r]e"] },
    { word: "eve", stage: "Stage 3", focus: "e|e", phonemes: ["e[v]e"] },
    { word: "delete", stage: "Stage 3", focus: "e|e", phonemes: ["d", "e", "l", "e[t]e"] },
    { word: "extreme", stage: "Stage 3", focus: "e|e", phonemes: ["e", "x", "t", "r", "e[m]e"] },
    { word: "theme", stage: "Stage 3", focus: "e|e", phonemes: ["th", "e[m]e"] },
    { word: "scene", stage: "Stage 3", focus: "e|e", phonemes: ["sc", "e[n]e"] },
    { word: "athlete", stage: "Stage 3", focus: "e|e", phonemes: ["a", "th", "l", "e[t]e"] },
    { word: "concrete", stage: "Stage 3", focus: "e|e", phonemes: ["c", "o", "n", "c", "r", "e[t]e"] },
    { word: "spread", stage: "Stage 4", focus: "ea as e", phonemes: ["s", "p", "r", "ea", "d"] },
    { word: "thread", stage: "Stage 4", focus: "ea as e", phonemes: ["th", "r", "ea", "d"] },
    { word: "meant", stage: "Stage 4", focus: "ea as e", phonemes: ["m", "ea", "n", "t"] },
    { word: "tread", stage: "Stage 4", focus: "ea as e", phonemes: ["t", "r", "ea", "d"] },
    { word: "sweat", stage: "Stage 4", focus: "ea as e", phonemes: ["s", "w", "ea", "t"] },
    { word: "head", stage: "Stage 4", focus: "ea as e", phonemes: ["h", "ea", "d"] },
    { word: "bread", stage: "Stage 4", focus: "ea as e", phonemes: ["b", "r", "ea", "d"] },
    { word: "health", stage: "Stage 4", focus: "ea as e", phonemes: ["h", "ea", "l", "th"] },
    { word: "dread", stage: "Stage 4", focus: "ea as e", phonemes: ["d", "r", "ea", "d"] },
    { word: "wealth", stage: "Stage 4", focus: "ea as e", phonemes: ["w", "ea", "l", "th"] },
    { word: "lead", stage: "Stage 4", focus: "ea as e", phonemes: ["l", "ea", "d"] },
    { word: "bandage", stage: "Stage 4", focus: "age as ij", phonemes: ["b", "an", "d", "age"] },
    { word: "passage", stage: "Stage 4", focus: "age as ij", phonemes: ["p", "a", "ss", "age"] },
    { word: "language", stage: "Stage 4", focus: "age as ij", phonemes: ["l", "a", "n", "gu", "age"] },
    { word: "voyage", stage: "Stage 4", focus: "age as ij", phonemes: ["v", "oy", "age"] },
    { word: "vintage", stage: "Stage 4", focus: "age as ij", phonemes: ["v", "i", "n", "t", "age"] },
    { word: "garbage", stage: "Stage 4", focus: "age as ij", phonemes: ["g", "ar", "b", "age"] },
    { word: "cottage", stage: "Stage 4", focus: "age as ij", phonemes: ["c", "o", "tt", "age"] },
    { word: "baggage", stage: "Stage 4", focus: "age as ij", phonemes: ["b", "a", "gg", "age"] },
    { word: "damage", stage: "Stage 4", focus: "age as ij", phonemes: ["d", "a", "m", "age"] },
    { word: "savage", stage: "Stage 4", focus: "age as ij", phonemes: ["s", "a", "v", "age"] },
    { word: "shortage", stage: "Stage 4", focus: "age as ij", phonemes: ["sh", "or", "t", "age"] },
    { word: "chain", stage: "Stage 2", focus: "ai", phonemes: ["ch", "ai", "n"] },
    { word: "hail", stage: "Stage 2", focus: "ai", phonemes: ["h", "ai", "l"] },
    { word: "drain", stage: "Stage 2", focus: "ai", phonemes: ["d", "r", "ai", "n"] },
    { word: "again", stage: "Stage 2", focus: "ai", phonemes: ["a", "g", "ai", "n"] },
    { word: "faith", stage: "Stage 2", focus: "ai", phonemes: ["f", "ai", "th"] },
    { word: "claim", stage: "Stage 2", focus: "ai", phonemes: ["c", "l", "ai", "m"] },
    { word: "bait", stage: "Stage 2", focus: "ai", phonemes: ["b", "ai", "t"] },
    { word: "strain", stage: "Stage 2", focus: "ai", phonemes: ["s", "t", "r", "ai", "n"] },
    { word: "paint", stage: "Stage 2", focus: "ai", phonemes: ["p", "ai", "n", "t"] },
    { word: "gain", stage: "Stage 2", focus: "ai", phonemes: ["g", "ai", "n"] },
    { word: "drew", stage: "Stage 3", focus: "ew", phonemes: ["d", "r", "ew"] },
    { word: "chew", stage: "Stage 3", focus: "ew", phonemes: ["ch", "ew"] },
    { word: "blew", stage: "Stage 3", focus: "ew", phonemes: ["b", "l", "ew"] },
    { word: "crew", stage: "Stage 3", focus: "ew", phonemes: ["c", "r", "ew"] },
    { word: "flew", stage: "Stage 3", focus: "ew", phonemes: ["f", "l", "ew"] },
    { word: "threw", stage: "Stage 3", focus: "ew", phonemes: ["th", "r", "ew"] },
    { word: "grew", stage: "Stage 3", focus: "ew", phonemes: ["g", "r", "ew"] },
    { word: "screw", stage: "Stage 3", focus: "ew", phonemes: ["s", "c", "r", "ew"] },
    { word: "new", stage: "Stage 3", focus: "ew", phonemes: ["n", "ew"] },
    { word: "knew", stage: "Stage 3", focus: "ew", phonemes: ["kn", "ew"] },
    { word: "hide", stage: "Stage 3", focus: "ew", phonemes: ["h", "i[d]e"] },
    { word: "life", stage: "Stage 3", focus: "i|e", phonemes: ["l", "i[f]e"] },
    { word: "crime", stage: "Stage 3", focus: "i|e", phonemes: ["c", "r", "i[m]e"] },
    { word: "mine", stage: "Stage 3", focus: "i|e", phonemes: ["m", "i[n]e"] },
    { word: "wipe", stage: "Stage 3", focus: "i|e", phonemes: ["w", "i[p]e"] },
    { word: "fire", stage: "Stage 3", focus: "i|e", phonemes: ["f", "i[r]e"] },
    { word: "spite", stage: "Stage 3", focus: "i|e", phonemes: ["s", "p", "i[t]e"] },
    { word: "drive", stage: "Stage 3", focus: "i|e", phonemes: ["d", "r", "i[v]e"] },
    { word: "size", stage: "Stage 3", focus: "i|e", phonemes: ["s", "i[z]e"] },
    { word: "smile", stage: "Stage 3", focus: "i|e", phonemes: ["s", "m", "i[l]e"] },
    { word: "comb", stage: "Stage 4", focus: "silent b", phonemes: ["c", "o", "mb"] },
    { word: "thumb", stage: "Stage 4", focus: "silent b", phonemes: ["th", "u", "mb"] },
    { word: "plumber", stage: "Stage 4", focus: "silent b", phonemes: ["p", "l", "u", "mb", "er"] },
    { word: "numb", stage: "Stage 4", focus: "silent b", phonemes: ["n", "u", "mb"] },
    { word: "crumb", stage: "Stage 4", focus: "silent b", phonemes: ["c", "r", "u", "mb"] },
    { word: "climb", stage: "Stage 4", focus: "silent b", phonemes: ["c", "l", "i", "mb"] },
    { word: "doubt", stage: "Stage 4", focus: "silent b", phonemes: ["d", "ou", "bt"] },
    { word: "lamb", stage: "Stage 4", focus: "silent b", phonemes: ["l", "a", "mb"] },
    { word: "limb", stage: "Stage 4", focus: "silent b", phonemes: ["l", "i", "mb"] },
    { word: "bomb", stage: "Stage 4", focus: "silent b", phonemes: ["b", "o", "mb"] },
    { word: "information", stage: "Stage 4", focus: "ation", phonemes: ["i", "n", "f", "or", "m", "ation"] },
    { word: "occupation", stage: "Stage 4", focus: "ation", phonemes: ["o", "cc", "u", "p", "ation"] },
    { word: "donation", stage: "Stage 4", focus: "ation", phonemes: ["d", "o", "n", "ation"] },
    { word: "radiation", stage: "Stage 4", focus: "ation", phonemes: ["r", "a", "d", "i", "ation"] },
    { word: "presentation", stage: "Stage 4", focus: "ation", phonemes: ["p", "r", "e", "s", "e", "n", "t", "ation"] },
    { word: "nation", stage: "Stage 4", focus: "ation", phonemes: ["n", "ation"] },
    { word: "separation", stage: "Stage 4", focus: "ation", phonemes: ["s", "e", "p", "ar", "ation"] },
    { word: "situation", stage: "Stage 4", focus: "ation", phonemes: ["s", "i", "t", "u", "ation"] },
    { word: "celebration", stage: "Stage 4", focus: "ation", phonemes: ["c", "e", "l", "e", "b", "r", "ation"] },
    { word: "ever", stage: "Stage 2", focus: "er", phonemes: ["e", "v", "er"] },
    { word: "never", stage: "Stage 2", focus: "er", phonemes: ["n", "e", "v", "er"] },
    { word: "anger", stage: "Stage 2", focus: "er", phonemes: ["a", "ng", "er"] },
    { word: "fern", stage: "Stage 2", focus: "er", phonemes: ["f", "er", "n"] },
    { word: "number", stage: "Stage 2", focus: "er", phonemes: ["n", "u", "m", "b", "er"] },
    { word: "finger", stage: "Stage 2", focus: "er", phonemes: ["f", "i", "n", "g", "er"] },
    { word: "bumper", stage: "Stage 2", focus: "er", phonemes: ["b", "u", "m", "p", "er"] },
    { word: "timber", stage: "Stage 2", focus: "er", phonemes: ["t", "i", "m", "b", "er"] },
    { word: "clever", stage: "Stage 2", focus: "er", phonemes: ["c", "l", "e", "v", "er"] },
    { word: "splinter", stage: "Stage 2", focus: "er", phonemes: ["s", "p", "l", "i", "n", "t", "er"] },
    { word: "place", stage: "Stage 3", focus: "ace", phonemes: ["p", "l", "ace"] },
    { word: "brace", stage: "Stage 3", focus: "ace", phonemes: ["b", "r", "ace"] },
    { word: "grace", stage: "Stage 3", focus: "ace", phonemes: ["g", "r", "ace"] },
    { word: "pace", stage: "Stage 3", focus: "ace", phonemes: ["p", "ace"] },
    { word: "embrace", stage: "Stage 3", focus: "ace", phonemes: ["e", "m", "b", "r", "ace"] },
    { word: "replace", stage: "Stage 3", focus: "ace", phonemes: ["r", "e", "p", "l", "ace"] },
    { word: "necklace", stage: "Stage 3", focus: "ace", phonemes: ["n", "e", "ck", "l", "ace"] },
    { word: "space", stage: "Stage 3", focus: "ace", phonemes: ["s", "p", "ace"] },
    { word: "trace", stage: "Stage 3", focus: "ace", phonemes: ["t", "r", "ace"] },
    { word: "disgrace", stage: "Stage 3", focus: "ace", phonemes: ["d", "i", "s", "g", "r", "ace"] },
    { word: "musician", stage: "Stage 4", focus: "ician", phonemes: ["m", "u", "s", "ician"] },
    { word: "magician", stage: "Stage 4", focus: "ician", phonemes: ["m", "a", "g", "ician"] },
    { word: "technician", stage: "Stage 4", focus: "ician", phonemes: ["t", "e", "ch", "n", "ician"] },
    { word: "politician", stage: "Stage 4", focus: "ician", phonemes: ["p", "o", "l", "i", "t", "ician"] },
    { word: "electrician", stage: "Stage 4", focus: "ician", phonemes: ["e", "l", "e", "c", "t", "r", "ician"] },
    { word: "physician", stage: "Stage 4", focus: "ician", phonemes: ["phy", "s", "ician"] },
    { word: "beautician", stage: "Stage 4", focus: "ician", phonemes: ["b", "eau", "t", "ician"] },
    { word: "dietician", stage: "Stage 4", focus: "ician", phonemes: ["d", "i", "e", "t", "ician"] },
    { word: "optician", stage: "Stage 4", focus: "ician", phonemes: ["o", "p", "t", "ician"] },
    { word: "mathematician", stage: "Stage 4", focus: "ician", phonemes: ["m", "a", "the", "m", "a", "t", "ician"] },
    { word: "fish", stage: "Stage 1.2", focus: "sh", phonemes: ["f", "i", "sh"] },
    { word: "wish", stage: "Stage 1.2", focus: "sh", phonemes: ["w", "i", "sh"] },
    { word: "shot", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "o", "t"] },
    { word: "shop", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "o", "p"] },
    { word: "shun", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "u", "n"] },
    { word: "cash", stage: "Stage 1.2", focus: "sh", phonemes: ["c", "a", "sh"] },
    { word: "shed", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "e", "d"] },
    { word: "rush", stage: "Stage 1.2", focus: "sh", phonemes: ["r", "u", "sh"] },
    { word: "gash", stage: "Stage 1.2", focus: "sh", phonemes: ["g", "a", "sh"] },
    { word: "ship", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "i", "p"] },
    { word: "hush", stage: "Stage 1.2", focus: "sh", phonemes: ["h", "u", "sh"] },
    { word: "sash", stage: "Stage 1.2", focus: "sh", phonemes: ["s", "a", "sh"] },
    { word: "dish", stage: "Stage 1.2", focus: "sh", phonemes: ["d", "i", "sh"] },
    { word: "bash", stage: "Stage 1.2", focus: "sh", phonemes: ["b", "a", "sh"] },
    { word: "rash", stage: "Stage 1.2", focus: "sh", phonemes: ["r", "a", "sh"] },
    { word: "dash", stage: "Stage 1.2", focus: "sh", phonemes: ["d", "a", "sh"] },
    { word: "mash", stage: "Stage 1.2", focus: "sh", phonemes: ["m", "a", "sh"] },
    { word: "lash", stage: "Stage 1.2", focus: "sh", phonemes: ["l", "a", "sh"] },
    { word: "gush", stage: "Stage 1.2", focus: "sh", phonemes: ["g", "u", "sh"] },
    { word: "shin", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "i", "n"] },
    { word: "shut", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "u", "t"] },
    { word: "brush", stage: "Stage 1.2", focus: "sh", phonemes: ["b", "r", "u", "sh"] },
    { word: "shelf", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "e", "l", "f"] },
    { word: "shift", stage: "Stage 1.2", focus: "sh", phonemes: ["sh", "i", "f", "t"] },
    { word: "finished", stage: "Stage 1.2", focus: "sh", phonemes: ["f", "i", "n", "i", "sh", "ed"] },
    { word: "chip", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "i", "p"] },
    { word: "such", stage: "Stage 1.2", focus: "ch", phonemes: ["s", "u", "ch"] },
    { word: "much", stage: "Stage 1.2", focus: "ch", phonemes: ["m", "u", "ch"] },
    { word: "lunch", stage: "Stage 1.2", focus: "ch", phonemes: ["l", "u", "n", "ch"] },
    { word: "chicken", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "i", "ck", "en"] },
    { word: "chin", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "i", "n"] },
    { word: "chum", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "u", "m"] },
    { word: "rich", stage: "Stage 1.2", focus: "ch", phonemes: ["r", "i", "ch"] },
    { word: "chat", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "a", "t"] },
    { word: "chop", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "o", "p"] },
    { word: "chug", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "u", "g"] },
    { word: "chap", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "a", "p"] },
    { word: "chest", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "e", "s", "t"] },
    { word: "child", stage: "Stage 1.2", focus: "ch", phonemes: ["ch", "i", "l", "d"] },
    { word: "that", stage: "Stage 1.2", focus: "th (v)", phonemes: ["th", "a", "t"] },
    { word: "them", stage: "Stage 1.2", focus: "th (v)", phonemes: ["th", "e", "m"] },
    { word: "this", stage: "Stage 1.2", focus: "th (v)", phonemes: ["th", "i", "s"] },
    { word: "then", stage: "Stage 1.2", focus: "th (v)", phonemes: ["th", "e", "n"] },
    { word: "than", stage: "Stage 1.2", focus: "th (v)", phonemes: ["th", "a", "n"] },
    { word: "with", stage: "Stage 1.2", focus: "th (u)", phonemes: ["w", "i", "th"] },
    { word: "think", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "i", "n", "k"] },
    { word: "moth", stage: "Stage 1.2", focus: "th (u)", phonemes: ["m", "o", "th"] },
    { word: "thus", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "u", "s"] },
    { word: "thud", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "u", "d"] },
    { word: "thin", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "i", "n"] },
    { word: "thug", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "u", "g"] },
    { word: "math", stage: "Stage 1.2", focus: "th (u)", phonemes: ["m", "a", "th"] },
    { word: "thrash", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "r", "a", "sh"] },
    { word: "thick", stage: "Stage 1.2", focus: "th (u)", phonemes: ["th", "i", "ck"] },
    { word: "when", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "e", "n"] },
    { word: "which", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "i", "ch"] },
    { word: "whip", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "i", "p"] },
    { word: "whim", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "i", "m"] },
    { word: "whiz", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "i", "z"] },
    { word: "wheel", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "ee", "l"] },
    { word: "whack", stage: "Stage 1.2", focus: "wh", phonemes: ["wh", "a", "ck"] },
    { word: "back", stage: "Stage 1.2", focus: "ck", phonemes: ["b", "a", "ck"] },
    { word: "duck", stage: "Stage 1.2", focus: "ck", phonemes: ["d", "u", "ck"] },
    { word: "kick", stage: "Stage 1.2", focus: "ck", phonemes: ["k", "i", "ck"] },
    { word: "sick", stage: "Stage 1.2", focus: "ck", phonemes: ["s", "i", "ck"] },
    { word: "pick", stage: "Stage 1.2", focus: "ck", phonemes: ["p", "i", "ck"] },
    { word: "black", stage: "Stage 1.2", focus: "ck", phonemes: ["b", "l", "a", "ck"] },
    { word: "chicken", stage: "Stage 1.2", focus: "ck", phonemes: ["ch", "i", "ck", "e", "n"] },
    { word: "luck", stage: "Stage 1.2", focus: "ck", phonemes: ["l", "u", "ck"] },
    { word: "muck", stage: "Stage 1.2", focus: "ck", phonemes: ["m", "u", "ck"] },
    { word: "lick", stage: "Stage 1.2", focus: "ck", phonemes: ["l", "i", "ck"] },
    { word: "buck", stage: "Stage 1.2", focus: "ck", phonemes: ["b", "u", "ck"] },
    { word: "tuck", stage: "Stage 1.2", focus: "ck", phonemes: ["t", "u", "ck"] },
    { word: "deck", stage: "Stage 1.2", focus: "ck", phonemes: ["d", "e", "ck"] },
    { word: "dock", stage: "Stage 1.2", focus: "ck", phonemes: ["d", "o", "ck"] },
    { word: "hack", stage: "Stage 1.2", focus: "ck", phonemes: ["h", "a", "ck"] },
    { word: "lack", stage: "Stage 1.2", focus: "ck", phonemes: ["l", "a", "ck"] },
    { word: "lock", stage: "Stage 1.2", focus: "ck", phonemes: ["l", "o", "ck"] },
    { word: "mock", stage: "Stage 1.2", focus: "ck", phonemes: ["m", "o", "ck"] },
    { word: "neck", stage: "Stage 1.2", focus: "ck", phonemes: ["n", "e", "ck"] },
    { word: "pack", stage: "Stage 1.2", focus: "ck", phonemes: ["p", "a", "ck"] },
    { word: "pink", stage: "Stage 1.2", focus: "Final k", phonemes: ["p", "i", "n", "k"] },
    { word: "milk", stage: "Stage 1.2", focus: "Final k", phonemes: ["m", "i", "l", "k"] },
    { word: "thank", stage: "Stage 1.2", focus: "Final k", phonemes: ["th", "a", "n", "k"] },
    { word: "think", stage: "Stage 1.2", focus: "Final k", phonemes: ["th", "i", "n", "k"] },
    { word: "desk", stage: "Stage 1.2", focus: "Final k", phonemes: ["d", "e", "s", "k"] },
    { word: "bank", stage: "Stage 1.2", focus: "Final k", phonemes: ["b", "a", "n", "k"] },
    { word: "silk", stage: "Stage 1.2", focus: "Final k", phonemes: ["s", "i", "l", "k"] },
    { word: "husk", stage: "Stage 1.2", focus: "Final k", phonemes: ["h", "u", "s", "k"] },
    { word: "dusk", stage: "Stage 1.2", focus: "Final k", phonemes: ["d", "u", "s", "k"] },
    { word: "junk", stage: "Stage 1.2", focus: "Final k", phonemes: ["j", "u", "n", "k"] },
    { word: "sank", stage: "Stage 1.2", focus: "Final k", phonemes: ["s", "a", "n", "k"] },
    { word: "musk", stage: "Stage 1.2", focus: "Final k", phonemes: ["m", "u", "s", "k"] },
    { word: "sunk", stage: "Stage 1.2", focus: "Final k", phonemes: ["s", "u", "n", "k"] },
    { word: "honk", stage: "Stage 1.2", focus: "Final k", phonemes: ["h", "o", "n", "k"] },
    { word: "sulk", stage: "Stage 1.2", focus: "Final k", phonemes: ["s", "u", "l", "k"] },
    { word: "sink", stage: "Stage 1.2", focus: "Final k", phonemes: ["s", "i", "n", "k"] },
    { word: "yank", stage: "Stage 1.2", focus: "Final k", phonemes: ["y", "a", "n", "k"] },
    { word: "chink", stage: "Stage 1.2", focus: "Final k", phonemes: ["ch", "i", "n", "k"] },
    { word: "bulk", stage: "Stage 1.2", focus: "Final k", phonemes: ["b", "u", "l", "k"] },
    { word: "disk", stage: "Stage 1.2", focus: "Final k", phonemes: ["d", "i", "s", "k"] },
    { word: "bunk", stage: "Stage 1.2", focus: "Final k", phonemes: ["b", "u", "n", "k"] },
    { word: "wink", stage: "Stage 1.2", focus: "Final k", phonemes: ["w", "i", "n", "k"] },
    { word: "see", stage: "Stage 1.2", focus: "ee", phonemes: ["s", "ee"] },
    { word: "keep", stage: "Stage 1.2", focus: "ee", phonemes: ["k", "ee", "p"] },
    { word: "week", stage: "Stage 1.2", focus: "ee", phonemes: ["w", "ee", "k"] },
    { word: "feet", stage: "Stage 1.2", focus: "ee", phonemes: ["f", "ee", "t"] },
    { word: "been", stage: "Stage 1.2", focus: "ee", phonemes: ["b", "ee", "n"] },
    { word: "teeth", stage: "Stage 1.2", focus: "ee", phonemes: ["t", "ee", "th"] },
    { word: "seem", stage: "Stage 1.2", focus: "ee", phonemes: ["s", "ee", "m"] },
    { word: "need", stage: "Stage 1.2", focus: "ee", phonemes: ["n", "ee", "d"] },
    { word: "queen", stage: "Stage 1.2", focus: "ee", phonemes: ["qu", "ee", "n"] },
    { word: "keen", stage: "Stage 1.2", focus: "ee", phonemes: ["k", "ee", "n"] },
    { word: "seek", stage: "Stage 1.2", focus: "ee", phonemes: ["s", "ee", "k"] },
    { word: "feed", stage: "Stage 1.2", focus: "ee", phonemes: ["f", "ee", "d"] },
    { word: "reed", stage: "Stage 1.2", focus: "ee", phonemes: ["r", "ee", "d"] },
    { word: "seed", stage: "Stage 1.2", focus: "ee", phonemes: ["s", "ee", "d"] },
    { word: "peep", stage: "Stage 1.2", focus: "ee", phonemes: ["p", "ee", "p"] },
    { word: "meek", stage: "Stage 1.2", focus: "ee", phonemes: ["m", "ee", "k"] },
    { word: "jeep", stage: "Stage 1.2", focus: "ee", phonemes: ["j", "ee", "p"] },
    { word: "feel", stage: "Stage 1.2", focus: "ee", phonemes: ["f", "ee", "l"] },
    { word: "rain", stage: "Stage 1.4", focus: "ai", phonemes: ["r", "ai", "n"] },
    { word: "chain", stage: "Stage 1.4", focus: "ai", phonemes: ["ch", "ai", "n"] },
    { word: "brain", stage: "Stage 1.4", focus: "ai", phonemes: ["b", "r", "ai", "n"] },
    { word: "train", stage: "Stage 1.4", focus: "ai", phonemes: ["t", "r", "ai", "n"] },
    { word: "laid", stage: "Stage 1.4", focus: "ai", phonemes: ["l", "ai", "d"] },
    { word: "drain", stage: "Stage 1.4", focus: "ai", phonemes: ["d", "r", "ai", "n"] },
    { word: "sail", stage: "Stage 1.4", focus: "ai", phonemes: ["s", "ai", "l"] },
    { word: "snail", stage: "Stage 1.4", focus: "ai", phonemes: ["s", "n", "ai", "l"] },
    { word: "mail", stage: "Stage 1.4", focus: "ai", phonemes: ["m", "ai", "l"] },
    { word: "hail", stage: "Stage 1.4", focus: "ai", phonemes: ["h", "ai", "l"] },
    { word: "nail", stage: "Stage 1.4", focus: "ai", phonemes: ["n", "ai", "l"] },
    { word: "wait", stage: "Stage 1.4", focus: "ai", phonemes: ["w", "ai", "t"] },
    { word: "stain", stage: "Stage 1.4", focus: "ai", phonemes: ["s", "t", "ai", "n"] },
    { word: "rail", stage: "Stage 1.4", focus: "ai", phonemes: ["r", "ai", "n"] },
    { word: "ball", stage: "Stage 1.4", focus: "all", phonemes: ["b", "all"] },
    { word: "call", stage: "Stage 1.4", focus: "all", phonemes: ["c", "all"] },
    { word: "small", stage: "Stage 1.4", focus: "all", phonemes: ["s", "m", "all"] },
    { word: "football", stage: "Stage 1.4", focus: "all", phonemes: ["f", "oo", "t", "b", "all"] },
    { word: "fall", stage: "Stage 1.4", focus: "all", phonemes: ["f", "all"] },
    { word: "hall", stage: "Stage 1.4", focus: "all", phonemes: ["h", "all"] },
    { word: "tall", stage: "Stage 1.4", focus: "all", phonemes: ["t", "all"] },
    { word: "stall", stage: "Stage 1.4", focus: "all", phonemes: ["s", "t", "all"] },
    { word: "wall", stage: "Stage 1.4", focus: "all", phonemes: ["w", "all"] },
    { word: "thing", stage: "Stage 1.4", focus: "ng", phonemes: ["th", "i", "ng"] },
    { word: "king", stage: "Stage 1.4", focus: "ng", phonemes: ["k", "i", "ng"] },
    { word: "song", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "o", "ng"] },
    { word: "along", stage: "Stage 1.4", focus: "ng", phonemes: ["a", "l", "o", "ng"] },
    { word: "bring", stage: "Stage 1.4", focus: "ng", phonemes: ["b", "r", "i", "ng"] },
    { word: "wing", stage: "Stage 1.4", focus: "ng", phonemes: ["w", "i", "ng"] },
    { word: "swing", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "w", "i", "ng"] },
    { word: "ping", stage: "Stage 1.4", focus: "ng", phonemes: ["p", "i", "ng"] },
    { word: "ding", stage: "Stage 1.4", focus: "ng", phonemes: ["d", "i", "ng"] },
    { word: "ring", stage: "Stage 1.4", focus: "ng", phonemes: ["r", "i", "ng"] },
    { word: "sting", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "t", "i", "ng"] },
    { word: "strong", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "t", "r", "o", "ng"] },
    { word: "rang", stage: "Stage 1.4", focus: "ng", phonemes: ["r", "a", "ng"] },
    { word: "sang", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "a", "ng"] },
    { word: "lung", stage: "Stage 1.4", focus: "ng", phonemes: ["l", "u", "ng"] },
    { word: "stung", stage: "Stage 1.4", focus: "ng", phonemes: ["s", "t", "u", "ng"] },
    { word: "cling", stage: "Stage 1.4", focus: "ng", phonemes: ["c", "l", "i", "ng"] },
    { word: "thorn", stage: "Stage 1.4", focus: "or", phonemes: ["th", "or", "n"] },
    { word: "forty", stage: "Stage 1.4", focus: "or", phonemes: ["f", "or", "t", "y"] },
    { word: "fork", stage: "Stage 1.4", focus: "or", phonemes: ["f", "or", "k"] },
    { word: "short", stage: "Stage 1.4", focus: "or", phonemes: ["sh", "or", "t"] },
    { word: "fort", stage: "Stage 1.4", focus: "or", phonemes: ["f", "or", "t"] },
    { word: "sort", stage: "Stage 1.4", focus: "or", phonemes: ["s", "or", "t"] },
    { word: "port", stage: "Stage 1.4", focus: "or", phonemes: ["p", "or", "t"] },
    { word: "sport", stage: "Stage 1.4", focus: "or", phonemes: ["s", "p", "or", "t"] },
    { word: "north", stage: "Stage 1.4", focus: "or", phonemes: ["n", "or", "th"] },
    { word: "cord", stage: "Stage 1.4", focus: "or", phonemes: ["c", "or", "d"] },
    { word: "storm", stage: "Stage 1.4", focus: "or", phonemes: ["s", "t", "or", "m"] },
    { word: "porch", stage: "Stage 1.4", focus: "or", phonemes: ["p", "or", "ch"] },
    { word: "corn", stage: "Stage 1.4", focus: "or", phonemes: ["c", "or", "n"] },
    { word: "worn", stage: "Stage 1.4", focus: "or", phonemes: ["w", "or", "n"] },
    { word: "shorn", stage: "Stage 1.4", focus: "or", phonemes: ["sh", "or", "n"] },
    { word: "forget", stage: "Stage 1.4", focus: "or", phonemes: ["f", "or", "g", "e", "t"] },
    { word: "report", stage: "Stage 1.4", focus: "or", phonemes: ["r", "e", "p", "or", "t"] },
    { word: "torn", stage: "Stage 1.4", focus: "or", phonemes: ["t", "or", "n"] },
    { word: "shark", stage: "Stage 1.4", focus: "ar", phonemes: ["sh", "ar", "k"] },
    { word: "farm", stage: "Stage 1.4", focus: "ar", phonemes: ["f", "ar", "m"] },
    { word: "arch", stage: "Stage 1.4", focus: "ar", phonemes: ["ar", "ch"] },
    { word: "spark", stage: "Stage 1.4", focus: "ar", phonemes: ["s", "p", "ar", "k"] },
    { word: "snarl", stage: "Stage 1.4", focus: "ar", phonemes: ["s", "n", "ar", "l"] },
    { word: "sharp", stage: "Stage 1.4", focus: "ar", phonemes: ["sh", "ar", "p"] },
    { word: "alarm", stage: "Stage 1.4", focus: "ar", phonemes: ["a", "l", "ar", "m"] },
    { word: "tarp", stage: "Stage 1.4", focus: "ar", phonemes: ["t", "ar", "p"] },
    { word: "mark", stage: "Stage 1.4", focus: "ar", phonemes: ["m", "ar", "k"] },
    { word: "scar", stage: "Stage 1.4", focus: "ar", phonemes: ["s", "c", "ar"] },
    { word: "chart", stage: "Stage 1.4", focus: "ar", phonemes: ["ch", "ar", "t"] },
    { word: "carpet", stage: "Stage 1.4", focus: "ar", phonemes: ["c", "ar", "p", "e", "t"] },
    { word: "smart", stage: "Stage 1.4", focus: "ar", phonemes: ["s", "m", "ar", "t"] },
    { word: "scarf", stage: "Stage 1.4", focus: "ar", phonemes: ["s", "c", "ar", "f"] },
    { word: "ever", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["e", "v", "er"] },
    { word: "under", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["u", "n", "d", "er"] },
    { word: "river", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["r", "i", "v", "er"] },
    { word: "sister", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["s", "i", "s", "t", "er"] },
    { word: "duster", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["d", "u", "s", "t", "er"] },
    { word: "anger", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["a", "n", "g", "er"] },
    { word: "splinter", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["s", "p", "l", "i", "n", "t", "er"] },
    { word: "winter", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["w", "i", "n", "t", "er"] },
    { word: "temper", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["t", "e", "m", "p", "er"] },
    { word: "silver", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["s", "i", "l", "v", "er"] },
    { word: "timber", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["t", "i", "m", "b", "er"] },
    { word: "clever", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["c", "l", "e", "v", "er"] },
    { word: "muster", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["m", "u", "s", "t", "er"] },
    { word: "never", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["n", "e", "v", "er"] },
    { word: "barter", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["b", "ar", "t", "er"] },
    { word: "bumper", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["b", "u", "m", "p", "er"] },
    { word: "finger", stage: "Stage 1.4", focus: "er (schwa)", phonemes: ["f", "i", "n", "g", "er"] },
    { word: "oil", stage: "Stage 1.4", focus: "oi", phonemes: ["oi", "l"] },
    { word: "point", stage: "Stage 1.4", focus: "oi", phonemes: ["p", "oi", "n", "t"] },
    { word: "spoil", stage: "Stage 1.4", focus: "oi", phonemes: ["s", "p", "oi", "l"] },
    { word: "noise", stage: "Stage 1.4", focus: "oi", phonemes: ["n", "oi", "se"] },
    { word: "foil", stage: "Stage 1.4", focus: "oi", phonemes: ["f", "oi", "l"] },
    { word: "toil", stage: "Stage 1.4", focus: "oi", phonemes: ["t", "oi", "l"] },
    { word: "boil", stage: "Stage 1.4", focus: "oi", phonemes: ["b", "oi", "l"] },
    { word: "joint", stage: "Stage 1.4", focus: "oi", phonemes: ["j", "oi", "n", "t"] },
    { word: "soil", stage: "Stage 1.4", focus: "oi", phonemes: ["s", "oi", "l"] },
    { word: "coin", stage: "Stage 1.4", focus: "oi", phonemes: ["c", "oi", "n"] },
    { word: "join", stage: "Stage 1.4", focus: "oi", phonemes: ["j", "oi", "n"] },
    { word: "oink", stage: "Stage 1.4", focus: "oi", phonemes: ["oi", "n", "k"] },
    { word: "void", stage: "Stage 1.4", focus: "oi", phonemes: ["v", "oi", "d"] },
    { word: "moist", stage: "Stage 1.4", focus: "oi", phonemes: ["m", "oi", "s", "t"] },
    { word: "hoist", stage: "Stage 1.4", focus: "oi", phonemes: ["h", "oi", "s", "t"] },
    { word: "off", stage: "Stage 2", focus: "ff", phonemes: ["o", "ff"] },
    { word: "cuff", stage: "Stage 2", focus: "ff", phonemes: ["c", "u", "ff"] },
    { word: "puff", stage: "Stage 2", focus: "ff", phonemes: ["p", "u", "ff"] },
    { word: "sniff", stage: "Stage 2", focus: "ff", phonemes: ["s", "n", "i", "ff"] },
    { word: "guff", stage: "Stage 2", focus: "ff", phonemes: ["g", "u", "ff"] },
    { word: "bluff", stage: "Stage 2", focus: "ff", phonemes: ["b", "l", "u", "ff"] },
    { word: "cliff", stage: "Stage 2", focus: "ff", phonemes: ["c", "l", "i", "ff"] },
    { word: "fluff", stage: "Stage 2", focus: "ff", phonemes: ["f", "l", "u", "ff"] },
    { word: "huff", stage: "Stage 2", focus: "ff", phonemes: ["h", "u", "ff"] },
    { word: "stuff", stage: "Stage 2", focus: "ff", phonemes: ["s", "t", "u", "ff"] },
    { word: "scoff", stage: "Stage 2", focus: "ff", phonemes: ["s", "c", "o", "ff"] },
    { word: "scuff", stage: "Stage 2", focus: "ff", phonemes: ["s", "c", "u", "ff"] },
    { word: "whiff", stage: "Stage 2", focus: "ff", phonemes: ["wh", "i", "ff"] },
    { word: "scruff", stage: "Stage 2", focus: "ff", phonemes: ["s", "c", "r", "u", "ff"] },
    { word: "cross", stage: "Stage 2", focus: "ss", phonemes: ["c", "r", "o", "ss"] },
    { word: "dress", stage: "Stage 2", focus: "ss", phonemes: ["d", "r", "e", "ss"] },
    { word: "moss", stage: "Stage 2", focus: "ss", phonemes: ["m", "o", "ss"] },
    { word: "boss", stage: "Stage 2", focus: "ss", phonemes: ["b", "o", "ss"] },
    { word: "mess", stage: "Stage 2", focus: "ss", phonemes: ["m", "e", "ss"] },
    { word: "bless", stage: "Stage 2", focus: "ss", phonemes: ["b", "l", "e", "ss"] },
    { word: "chess", stage: "Stage 2", focus: "ss", phonemes: ["ch", "e", "ss"] },
    { word: "toss", stage: "Stage 2", focus: "ss", phonemes: ["t", "o", "ss"] },
    { word: "press", stage: "Stage 2", focus: "ss", phonemes: ["p", "r", "e", "ss"] },
    { word: "fuss", stage: "Stage 2", focus: "ss", phonemes: ["f", "u", "ss"] },
    { word: "mass", stage: "Stage 2", focus: "ss", phonemes: ["m", "a", "ss"] },
    { word: "floss", stage: "Stage 2", focus: "ss", phonemes: ["f", "l", "o", "ss"] },
    { word: "stress", stage: "Stage 2", focus: "ss", phonemes: ["s", "t", "r", "e", "ss"] },
    { word: "gloss", stage: "Stage 2", focus: "ss", phonemes: ["g", "l", "o", "ss"] },
    { word: "smell", stage: "Stage 2", focus: "ll", phonemes: ["s", "m", "e", "ll"] },
    { word: "spill", stage: "Stage 2", focus: "ll", phonemes: ["s", "p", "e", "ll"] },
    { word: "swell", stage: "Stage 2", focus: "ll", phonemes: ["s", "w", "e", "ll"] },
    { word: "grill", stage: "Stage 2", focus: "ll", phonemes: ["g", "r", "i", "ll"] },
    { word: "chill", stage: "Stage 2", focus: "ll", phonemes: ["ch", "i", "ll"] },
    { word: "skill", stage: "Stage 2", focus: "ll", phonemes: ["s", "k", "i", "ll"] },
    { word: "drill", stage: "Stage 2", focus: "ll", phonemes: ["d", "r", "i", "ll"] },
    { word: "sell", stage: "Stage 2", focus: "ll", phonemes: ["s", "e", "ll"] },
    { word: "dull", stage: "Stage 2", focus: "ll", phonemes: ["d", "u", "ll"] },
    { word: "dwell", stage: "Stage 2", focus: "ll", phonemes: ["d", "w", "e", "ll"] },
    { word: "scroll", stage: "Stage 2", focus: "ll", phonemes: ["s", "c", "r", "o", "ll"] },
    { word: "troll", stage: "Stage 2", focus: "ll", phonemes: ["t", "r", "o", "ll"] },
    { word: "thrill", stage: "Stage 2", focus: "ll", phonemes: ["th", "r", "i", "ll"] },
    { word: "quill", stage: "Stage 2", focus: "ll", phonemes: ["qu", "i", "ll"] },
    { word: "stroll", stage: "Stage 2", focus: "ll", phonemes: ["s", "t", "r", "o", "ll"] },
    { word: "shrill", stage: "Stage 2", focus: "ll", phonemes: ["sh", "r", "i", "ll"] },
    { word: "boat", stage: "Stage 2", focus: "oa", phonemes: ["b", "oa", "t"] },
    { word: "road", stage: "Stage 2", focus: "oa", phonemes: ["r", "oa", "d"] },
    { word: "coat", stage: "Stage 2", focus: "oa", phonemes: ["c", "oa", "t"] },
    { word: "laod", stage: "Stage 2", focus: "oa", phonemes: ["l", "oa", "d"] },
    { word: "roast", stage: "Stage 2", focus: "oa", phonemes: ["r", "oa", "s", "t"] },
    { word: "croak", stage: "Stage 2", focus: "oa", phonemes: ["c", "r", "oa", "k"] },
    { word: "moan", stage: "Stage 2", focus: "oa", phonemes: ["m", "oa", "n"] },
    { word: "goat", stage: "Stage 2", focus: "oa", phonemes: ["g", "oa", "t"] },
    { word: "loaf", stage: "Stage 2", focus: "oa", phonemes: ["l", "oa", "ff"] },
    { word: "toad", stage: "Stage 2", focus: "oa", phonemes: ["t", "oa", "d"] },
    { word: "soak", stage: "Stage 2", focus: "oa", phonemes: ["s", "oa", "k"] },
    { word: "foam", stage: "Stage 2", focus: "oa", phonemes: ["f", "oa", "m"] },
    { word: "cloak", stage: "Stage 2", focus: "oa", phonemes: ["c", "l", "oa", "k"] },
    { word: "coach", stage: "Stage 2", focus: "oa", phonemes: ["c", "oa", "ch"] },
    { word: "boast", stage: "Stage 2", focus: "oa", phonemes: ["b", "oa", "s", "t"] },
    { word: "groan", stage: "Stage 2", focus: "oa", phonemes: ["g", "r", "oa", "n"] },
    { word: "toe", stage: "Stage 2", focus: "oe", phonemes: ["t", "oe"] },
    { word: "foe", stage: "Stage 2", focus: "oe", phonemes: ["f", "oe"] },
    { word: "tiptoe", stage: "Stage 2", focus: "oe", phonemes: ["t", "i", "p", "t", "oe"] },
    { word: "goes", stage: "Stage 2", focus: "oe", phonemes: ["g", "oe", "s"] },
    { word: "doe", stage: "Stage 2", focus: "oe", phonemes: ["d", "oe"] },
    { word: "hoe", stage: "Stage 2", focus: "oe", phonemes: ["h", "oe"] },
    { word: "woe", stage: "Stage 2", focus: "oe", phonemes: ["w", "oe"] },
    { word: "oboe", stage: "Stage 2", focus: "oe", phonemes: ["o", "b", "oe"] },
    { word: "snow", stage: "Stage 2", focus: "ow", phonemes: ["s", "n", "ow"] },
    { word: "yellow", stage: "Stage 2", focus: "ow", phonemes: ["y", "e", "ll", "ow"] },
    { word: "grow", stage: "Stage 2", focus: "ow", phonemes: ["g", "r", "ow"] },
    { word: "window", stage: "Stage 2", focus: "ow", phonemes: ["w", "i", "n", "d", "ow"] },
    { word: "follow", stage: "Stage 2", focus: "ow", phonemes: ["f", "e", "ll", "ow"] },
    { word: "show", stage: "Stage 2", focus: "ow", phonemes: ["sh", "ow"] },
    { word: "slow", stage: "Stage 2", focus: "ow", phonemes: ["s", "l", "ow"] },
    { word: "throw", stage: "Stage 2", focus: "ow", phonemes: ["th", "r", "ow"] },
    { word: "pillow", stage: "Stage 2", focus: "ow", phonemes: ["p", "i", "ll", "ow"] },
    { word: "glow", stage: "Stage 2", focus: "ow", phonemes: ["g", "l", "ow"] },
    { word: "elbow", stage: "Stage 2", focus: "ow", phonemes: ["e", "l", "b", "ow"] },
    { word: "shown", stage: "Stage 2", focus: "ow", phonemes: ["sh", "ow", "n"] },
    { word: "billow", stage: "Stage 2", focus: "ow", phonemes: ["b", "i", "ll", "ow"] },
    { word: "read", stage: "Stage 2", focus: "ea", phonemes: ["r", "ea", "d"] },
    { word: "seal", stage: "Stage 2", focus: "ea", phonemes: ["s", "ea", "ll"] },
    { word: "season", stage: "Stage 2", focus: "ea", phonemes: ["s", "ea", "s", "o", "n"] },
    { word: "clean", stage: "Stage 2", focus: "ea", phonemes: ["c", "l", "ea", "n"] },
    { word: "neat", stage: "Stage 2", focus: "ea", phonemes: ["n", "ea", "t"] },
    { word: "preach", stage: "Stage 2", focus: "ea", phonemes: ["p", "r", "ea", "ch"] },
    { word: "teach", stage: "Stage 2", focus: "ea", phonemes: ["t", "ea", "ch"] },
    { word: "dream", stage: "Stage 2", focus: "ea", phonemes: ["d", "r", "ea", "m"] },
    { word: "streak", stage: "Stage 2", focus: "ea", phonemes: ["s", "t", "r", "ea", "k"] },
    { word: "cream", stage: "Stage 2", focus: "ea", phonemes: ["c", "r", "ea", "m"] },
    { word: "steam", stage: "Stage 2", focus: "ea", phonemes: ["s", "t", "ea", "m"] },
    { word: "wheat", stage: "Stage 2", focus: "ea", phonemes: ["wh", "ea", "t"] },
    { word: "cheap", stage: "Stage 2", focus: "ea", phonemes: ["ch", "ea", "p"] },
    { word: "beam", stage: "Stage 2", focus: "ea", phonemes: ["b", "ea", "m"] },
    { word: "repeat", stage: "Stage 2", focus: "ea", phonemes: ["r", "e", "p", "ea", "t"] },
    { word: "speak", stage: "Stage 2", focus: "ea", phonemes: ["s", "p", "ea", "k"] },
    { word: "hue", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["h", "ue"] },
    { word: "cue", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["c", "ue"] },
    { word: "due", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["d", "ue"] },
    { word: "new", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["n", "ew"] },
    { word: "few", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["f", "ew"] },
    { word: "stew", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["s", "t", "ew"] },
    { word: "dew", stage: "Stage 2", focus: "ue/ew as y+oo", phonemes: ["d", "ew"] },
    { word: "blue", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["b", "l", "ue"] },
    { word: "true", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["t", "r", "ue"] },
    { word: "glue", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["g", "l", "ue"] },
    { word: "flue", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["f", "l", "ue"] },
    { word: "clue", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["c", "l", "ue"] },
    { word: "sue", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["s", "ue"] },
    { word: "flew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["f", "l", "ew"] },
    { word: "threw", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["th", "r", "ew"] },
    { word: "grew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["g", "r", "ew"] },
    { word: "chew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["ch", "ew"] },
    { word: "blew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["b", "l", "ew"] },
    { word: "crew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["c", "r", "ew"] },
    { word: "screw", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["s", "c", "r", "ew"] },
    { word: "drew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["d", "r", "ew"] },
    { word: "brew", stage: "Stage 2", focus: "ue/ew as oo", phonemes: ["b", "r", "ew"] },
    { word: "scoop", stage: "Stage 2", focus: "oo", phonemes: ["s", "c", "oo", "p"] },
    { word: "smooth", stage: "Stage 2", focus: "oo", phonemes: ["s", "m", "oo", "th"] },
    { word: "school", stage: "Stage 2", focus: "oo", phonemes: ["s", "ch", "oo", "ll"] },
    { word: "cocoon", stage: "Stage 2", focus: "oo", phonemes: ["co", "c", "oo", "n"] },
    { word: "scooter", stage: "Stage 2", focus: "oo", phonemes: ["s", "c", "oo", "t", "er"] },
    { word: "gloomy", stage: "Stage 2", focus: "oo", phonemes: ["g", "l", "oo", "m", "y"] },
    { word: "cartoon", stage: "Stage 2", focus: "oo", phonemes: ["c", "ar", "t", "oo", "n"] },
    { word: "troop", stage: "Stage 2", focus: "oo", phonemes: ["t", "r", "oo", "p"] },
    { word: "boost", stage: "Stage 2", focus: "oo", phonemes: ["b", "oo", "s", "t"] },
    { word: "proof", stage: "Stage 2", focus: "oo", phonemes: ["p", "r", "oo", "f"] },
    { word: "droop", stage: "Stage 2", focus: "oo", phonemes: ["d", "r", "oo", "f"] },
    { word: "stoop", stage: "Stage 2", focus: "oo", phonemes: ["s", "t", "oo", "p"] },
    { word: "brown", stage: "Stage 2", focus: "ow", phonemes: ["b", "r", "ow", "n"] },
    { word: "growl", stage: "Stage 2", focus: "ow", phonemes: ["g", "r", "ow", "l"] },
    { word: "flower", stage: "Stage 2", focus: "ow", phonemes: ["f", "l", "ow", "er"] },
    { word: "crown", stage: "Stage 2", focus: "ow", phonemes: ["c", "r", "ow", "n"] },
    { word: "gown", stage: "Stage 2", focus: "ow", phonemes: ["g", "ow", "n"] },
    { word: "clown", stage: "Stage 2", focus: "ow", phonemes: ["c", "l", "ow", "n"] },
    { word: "frown", stage: "Stage 2", focus: "ow", phonemes: ["f", "r", "ow", "n"] },
    { word: "shower", stage: "Stage 2", focus: "ow", phonemes: ["sh", "ow", "er"] },
    { word: "prowl", stage: "Stage 2", focus: "ow", phonemes: ["p", "r", "ow", "l"] },
    { word: "rowdy", stage: "Stage 2", focus: "ow", phonemes: ["r", "ow", "d", "y"] },
    { word: "scowl", stage: "Stage 2", focus: "ow", phonemes: ["s", "c", "ow", "l"] },
    { word: "tower", stage: "Stage 2", focus: "ow", phonemes: ["t", "ow", "er"] },
    { word: "powder", stage: "Stage 2", focus: "ow", phonemes: ["p", "ow", "d", "er"] },
    { word: "found", stage: "Stage 2", focus: "ou", phonemes: ["f", "ou", "n", "d"] },
    { word: "mouse", stage: "Stage 2", focus: "ou", phonemes: ["m", "ou", "se"] },
    { word: "house", stage: "Stage 2", focus: "ou", phonemes: ["h", "ou", "se"] },
    { word: "couch", stage: "Stage 2", focus: "ou", phonemes: ["c", "ou", "ch"] },
    { word: "shout", stage: "Stage 2", focus: "ou", phonemes: ["sh", "ou", "t"] },
    { word: "hound", stage: "Stage 2", focus: "ou", phonemes: ["h", "ou", "n", "d"] },
    { word: "aloud", stage: "Stage 2", focus: "ou", phonemes: ["a", "l", "ou", "d"] },
    { word: "amount", stage: "Stage 2", focus: "ou", phonemes: ["a", "m", "ou", "n", "t"] },
    { word: "foul", stage: "Stage 2", focus: "ou", phonemes: ["f", "ou", "ll"] },
    { word: "noun", stage: "Stage 2", focus: "ou", phonemes: ["n", "ou", "n"] },
    { word: "sour", stage: "Stage 2", focus: "ou", phonemes: ["s", "ou", "r"] },
    { word: "proud", stage: "Stage 2", focus: "ou", phonemes: ["p", "r", "ou", "d"] },
    { word: "scout", stage: "Stage 2", focus: "ou", phonemes: ["s", "c", "ou", "t"] },
    { word: "face", stage: "Stage 3", focus: "ce as s", phonemes: ["f", "a", "ce"] },
    { word: "place", stage: "Stage 3", focus: "ce as s", phonemes: ["p", "l", "a", "ce"] },
    { word: "space", stage: "Stage 3", focus: "ce as s", phonemes: ["s", "p", "a", "ce"] },
    { word: "trace", stage: "Stage 3", focus: "ce as s", phonemes: ["t", "r", "a", "ce"] },
    { word: "pace", stage: "Stage 3", focus: "ce as s", phonemes: ["p", "a", "ce"] },
    { word: "lace", stage: "Stage 3", focus: "ce as s", phonemes: ["l", "a", "ce"] },
    { word: "peace", stage: "Stage 3", focus: "ce as s", phonemes: ["p", "ea", "ce"] },
    { word: "grace", stage: "Stage 3", focus: "ce as s", phonemes: ["g", "r", "a", "ce"] },
    { word: "brace", stage: "Stage 3", focus: "ce as s", phonemes: ["b", "r", "a", "ce"] },
    { word: "since", stage: "Stage 3", focus: "ce as s", phonemes: ["s", "i", "n", "ce"] },
    { word: "dance", stage: "Stage 3", focus: "ce as s", phonemes: ["d", "a", "n", "ce"] },
    { word: "slice", stage: "Stage 3", focus: "ce as s", phonemes: ["s", "l", "i", "ce"] },
    { word: "twice", stage: "Stage 3", focus: "ce as s", phonemes: ["t", "w", "i", "ce"] },
    { word: "horse", stage: "Stage 3", focus: "se as s", phonemes: ["h", "or", "se"] },
    { word: "geese", stage: "Stage 3", focus: "se as s", phonemes: ["g", "ee", "se"] },
    { word: "goose", stage: "Stage 3", focus: "se as s", phonemes: ["g", "oo", "se"] },
    { word: "grease", stage: "Stage 3", focus: "se as s", phonemes: ["g", "r", "ea", "se"] },
    { word: "loose", stage: "Stage 3", focus: "se as s", phonemes: ["l", "oo", "se"] },
    { word: "curse", stage: "Stage 3", focus: "se as s", phonemes: ["c", "ur", "se"] },
    { word: "nurse", stage: "Stage 3", focus: "se as s", phonemes: ["n", "ur", "se"] },
    { word: "rinse", stage: "Stage 3", focus: "se as s", phonemes: ["r", "i", "n", "se"] },
    { word: "house", stage: "Stage 3", focus: "se as s", phonemes: ["h", "ou", "se"] },
    { word: "mouse", stage: "Stage 3", focus: "se as s", phonemes: ["m", "ou", "se"] },
    { word: "night", stage: "Stage 3", focus: "igh", phonemes: ["n", "igh", "t"] },
    { word: "high", stage: "Stage 3", focus: "igh", phonemes: ["h", "igh"] },
    { word: "right", stage: "Stage 3", focus: "igh", phonemes: ["r", "igh", "t"] },
    { word: "might", stage: "Stage 3", focus: "igh", phonemes: ["m", "igh", "t"] },
    { word: "light", stage: "Stage 3", focus: "igh", phonemes: ["l", "igh", "t"] },
    { word: "fight", stage: "Stage 3", focus: "igh", phonemes: ["f", "igh", "t"] },
    { word: "slight", stage: "Stage 3", focus: "igh", phonemes: ["s", "l", "igh", "t"] },
    { word: "sight", stage: "Stage 3", focus: "igh", phonemes: ["s", "igh", "t"] },
    { word: "fright", stage: "Stage 3", focus: "igh", phonemes: ["f", "r", "igh", "t"] },
    { word: "flight", stage: "Stage 3", focus: "igh", phonemes: ["f", "l", "igh", "t"] },
    { word: "bright", stage: "Stage 3", focus: "igh", phonemes: ["b", "r", "igh", "t"] },
    { word: "tight", stage: "Stage 3", focus: "igh", phonemes: ["t", "igh", "t"] },
    { word: "thigh", stage: "Stage 3", focus: "igh", phonemes: ["th", "igh"] },
    { word: "match", stage: "Stage 3", focus: "tch", phonemes: ["m", "a", "tch"] },
    { word: "latch", stage: "Stage 3", focus: "tch", phonemes: ["l", "a", "tch"] },
    { word: "batch", stage: "Stage 3", focus: "tch", phonemes: ["b", "a", "tch"] },
    { word: "patch", stage: "Stage 3", focus: "tch", phonemes: ["p", "a", "tch"] },
    { word: "hatch", stage: "Stage 3", focus: "tch", phonemes: ["h", "a", "tch"] },
    { word: "snatch", stage: "Stage 3", focus: "tch", phonemes: ["s", "n", "a", "tch"] },
    { word: "catch", stage: "Stage 3", focus: "tch", phonemes: ["c", "a", "tch"] },
    { word: "switch", stage: "Stage 3", focus: "tch", phonemes: ["s", "w", "i", "tch"] },
    { word: "fetch", stage: "Stage 3", focus: "tch", phonemes: ["f", "e", "tch"] },
    { word: "sketch", stage: "Stage 3", focus: "tch", phonemes: ["s", "k", "e", "tch"] },
    { word: "blotch", stage: "Stage 3", focus: "tch", phonemes: ["b", "l", "o", "tch"] },
    { word: "scratch", stage: "Stage 3", focus: "tch", phonemes: ["s", "c", "r", "a", "tch"] },
    { word: "cousin", stage: "Stage 5", focus: "ou as u", phonemes: ["c", "ou", "s", "i", "n"] },
    { word: "country", stage: "Stage 5", focus: "ou as u", phonemes: ["c", "ou", "n", "t", "r", "y"] },
    { word: "southern", stage: "Stage 5", focus: "ou as u", phonemes: ["s", "ou", "th", "er", "n"] },
    { word: "young", stage: "Stage 5", focus: "ou as u", phonemes: ["y", "ou", "ng"] },
    { word: "couple", stage: "Stage 5", focus: "ou as u", phonemes: ["c", "ou", "p", "le"] },
    { word: "flourish", stage: "Stage 5", focus: "ou as u", phonemes: ["f", "l", "ou", "r", "i", "sh"] },
    { word: "encourage", stage: "Stage 5", focus: "ou as u", phonemes: ["e", "n", "c", "ou", "r", "age"] },
    { word: "nourish", stage: "Stage 5", focus: "ou as u", phonemes: ["n", "ou", "r", "i", "sh"] },
    { word: "courage", stage: "Stage 5", focus: "ou as u", phonemes: ["c", "ou", "r", "age"] },
    { word: "discourage", stage: "Stage 5", focus: "ou as u", phonemes: ["d", "i", "s", "c", "ou", "r", "age"] },
    { word: "trouble", stage: "Stage 5", focus: "ou as u", phonemes: ["t", "r", "ou", "b", "le"] },
    { word: "double", stage: "Stage 5", focus: "ou as u", phonemes: ["d", "ou", "b", "le"] },
    { word: "centre", stage: "Stage 5", focus: "re as swha er", phonemes: ["c", "e", "n", "t", "re"] },
    { word: "litre", stage: "Stage 5", focus: "re as swha er", phonemes: ["l", "i", "t", "re"] },
    //{ word: "kilometre", stage: "Stage 5", focus: "re as swha er", phonemes: ["k", "i", "l", "o", "m", "e", "t", "re"] },
    { word: "metre", stage: "Stage 5", focus: "re as swha er", phonemes: ["m", "e", "t", "re"] },
    //{ word: "millimetre", stage: "Stage 5", focus: "re as swha er", phonemes: ["m", "i", "ll", "i", "m", "e", "t", "re"] },
    //{ word: "centimetre", stage: "Stage 5", focus: "re as swha er", phonemes: ["c", "e", "n", "t", "i", "m", "e", "t", "re"] },
    { word: "calibre", stage: "Stage 5", focus: "re as swha er", phonemes: ["c", "a", "l", "i", "b", "re"] },
    { word: "lyre", stage: "Stage 5", focus: "re as swha er", phonemes: ["l", "y", "re"] },
    { word: "fibre", stage: "Stage 5", focus: "re as swha er", phonemes: ["f", "i", "b", "re"] },
    { word: "ochre", stage: "Stage 5", focus: "re as swha er", phonemes: ["o", "ch", "re"] },
    { word: "acre", stage: "Stage 5", focus: "re as swha er", phonemes: ["a", "c", "re"] },
    { word: "meagre", stage: "Stage 5", focus: "re as swha er", phonemes: ["m", "ea", "g", "re"] },
    { word: "massacre", stage: "Stage 5", focus: "re as swha er", phonemes: ["m", "a", "ss", "a", "c", "re"] },
    { word: "anguish", stage: "Stage 5", focus: "u as w", phonemes: ["an", "g", "u", "i", "sh"] },
    { word: "bilingual", stage: "Stage 5", focus: "u as w", phonemes: ["b", "i", "l", "i", "ng", "u", "al"] },
    //{ word: "distinguish", stage: "Stage 5", focus: "u as w", phonemes: ["d", "i", "s", "t", "i", "n", "g", "u", "i", "sh"] },
    //{ word: "extinguish", stage: "Stage 5", focus: "u as w", phonemes: ["ex", "t", "i", "n", "g", "u", "i", "sh"] },
    //{ word: "guacamole", stage: "Stage 5", focus: "u as w", phonemes: ["g", "u", "a", "c", "a", "m", "o", "l", "e"] },
    { word: "guava", stage: "Stage 5", focus: "u as w", phonemes: ["g", "u", "a", "v", "a"] },
    { word: "iguana", stage: "Stage 5", focus: "u as w", phonemes: ["i", "g", "u", "a", "n", "a"] },
    { word: "language", stage: "Stage 5", focus: "u as w", phonemes: ["l", "a", "n", "g", "u", "age"] },
    { word: "languish", stage: "Stage 5", focus: "u as w", phonemes: ["l", "a", "ng", "u", "age"] },
    //{ word: "linguistics", stage: "Stage 5", focus: "u as w", phonemes: ["l", "i", "n", "g", "u", "i", "s", "t", "i", "c", "s"] },
    { word: "sanguine", stage: "Stage 5", focus: "u as w", phonemes: ["s", "a", "n", "g", "u", "i[n]e"] },
    { word: "penguin", stage: "Stage 5", focus: "u as w", phonemes: ["p", "e", "n", "g", "u", "i", "n"] },
    { word: "ensuite", stage: "Stage 5", focus: "u as w", phonemes: ["en", "s", "u", "i", "te"] },
    { word: "suave", stage: "Stage 5", focus: "u as w", phonemes: ["s", "u", "a", "ve"] },
    { word: "suite", stage: "Stage 5", focus: "u as w", phonemes: ["s", "u", "i", "te"] },
    { word: "genius", stage: "Stage 5", focus: "e as ee", phonemes: ["g", "e", "n", "i", "us"] },
    { word: "menial", stage: "Stage 5", focus: "e as ee", phonemes: ["m", "e", "n", "i", "al"] },
    { word: "meteor", stage: "Stage 5", focus: "e as ee", phonemes: ["m", "e", "t", "e", "or"] },
    { word: "decoy", stage: "Stage 5", focus: "e as ee", phonemes: ["d", "e", "c", "oy"] },
    { word: "torpedo", stage: "Stage 5", focus: "e as ee", phonemes: ["t", "or", "p", "e", "d", "o"] },
    { word: "recent", stage: "Stage 5", focus: "e as ee", phonemes: ["r", "e", "c", "e", "n", "t"] },
    { word: "previous", stage: "Stage 5", focus: "e as ee", phonemes: ["p", "r", "e", "v", "i", "ous"] },
    { word: "lethal", stage: "Stage 5", focus: "e as ee", phonemes: ["l", "e", "th", "al"] },
    { word: "edict", stage: "Stage 5", focus: "e as ee", phonemes: ["e", "d", "ic", "t"] },
    //{ word: "dandelion", stage: "Stage 5", focus: "e as ee", phonemes: ["d", "an", "d", "e", "l", "i", "o", "n"] },
    { word: "flier", stage: "Stage 5", focus: "i as igh", phonemes: ["f", "l", "i", "er"] },
    { word: "pirate", stage: "Stage 5", focus: "i as igh", phonemes: ["p", "i", "r", "a[t]e"] },
    { word: "siphon", stage: "Stage 5", focus: "i as igh", phonemes: ["s", "i", "ph", "on"] },
    { word: "violin", stage: "Stage 5", focus: "i as igh", phonemes: ["v", "i", "o", "l", "i", "n"] },
    { word: "trial", stage: "Stage 5", focus: "i as igh", phonemes: ["t", "r", "i", "al"] },
    { word: "tidal", stage: "Stage 5", focus: "i as igh", phonemes: ["t", "i", "d", "al"] },
    { word: "siren", stage: "Stage 5", focus: "i as igh", phonemes: ["s", "i", "r", "en"] },
    { word: "silently", stage: "Stage 5", focus: "i as igh", phonemes: ["s", "i", "l", "e", "n", "t", "ly"] },
    { word: "crisis", stage: "Stage 5", focus: "i as igh", phonemes: ["c", "r", "i", "s", "i", "s"] },
    { word: "diary", stage: "Stage 5", focus: "i as igh", phonemes: ["d", "i", "ar", "y"] },
    { word: "miserable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["m", "i", "s", "er", "able"] },
    { word: "agreeable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["a", "g", "r", "ee", "able"] },
    { word: "unreasonable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["un", "r", "ea", "s", "on", "able"] },
    { word: "perishable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["p", "er", "i", "sh", "able"] },
    { word: "commendable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["c", "o", "mm", "e", "n", "d", "able"] },
    { word: "available", stage: "Stage 5", focus: "Suffix - able", phonemes: ["a", "v", "ai", "l", "able"] },
    { word: "valuable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["v", "a", "l", "u", "able"] },
    { word: "irredeemable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["i", "rr", "e", "d", "ee", "m", "able"] },
    { word: "capable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["c", "a", "p", "able"] },
    { word: "favourable", stage: "Stage 5", focus: "Suffix - able", phonemes: ["f", "a", "v", "our", "able"] },
    { word: "baroque", stage: "Stage 6", focus: "que as k", phonemes: ["b", "ar", "o", "que"] },
    { word: "boutique", stage: "Stage 6", focus: "que as k", phonemes: ["b", "ou", "t", "i", "que"] },
    { word: "brusque", stage: "Stage 6", focus: "que as k", phonemes: ["b", "r", "u", "s", "que"] },
    { word: "cheque", stage: "Stage 6", focus: "que as k", phonemes: ["ch", "e", "que"] },
    { word: "critique", stage: "Stage 6", focus: "que as k", phonemes: ["c", "r", "i", "t", "i", "que"] },
    { word: "grotesque", stage: "Stage 6", focus: "que as k", phonemes: ["g", "r", "o", "t", "e", "s", "que"] },
    { word: "marquee", stage: "Stage 6", focus: "que as k", phonemes: ["m", "ar", "que", "e"] },
    { word: "oblique", stage: "Stage 6", focus: "que as k", phonemes: ["o", "b", "l", "i", "que"] },
    { word: "opaque", stage: "Stage 6", focus: "que as k", phonemes: ["o", "p", "a", "que"] },
    { word: "physique", stage: "Stage 6", focus: "que as k", phonemes: ["ph", "y", "s", "i", "que"] },
    { word: "mystique", stage: "Stage 6", focus: "que as k", phonemes: ["m", "y", "s", "t", "i", "que"] },
    //{ word: "picturesque", stage: "Stage 6", focus: "que as k", phonemes: ["p", "i", "c", "t", "ur", "e", "s", "que"] },
    { word: "plaque", stage: "Stage 6", focus: "que as k", phonemes: ["p", "l", "a", "que"] },
    { word: "technique", stage: "Stage 6", focus: "que as k", phonemes: ["t", "e", "ch", "n", "i", "que"] },
    { word: "unique", stage: "Stage 6", focus: "que as k", phonemes: ["u", "n", "i", "que"] },
    { word: "ghost", stage: "Stage 6", focus: "silent h", phonemes: ["gh", "o", "s", "t"] },
    { word: "dinghy", stage: "Stage 6", focus: "silent h", phonemes: ["d", "i", "n", "gh", "y"] },
    { word: "gherkin", stage: "Stage 6", focus: "silent h", phonemes: ["gh", "er", "k", "i", "n"] },
    { word: "aghast", stage: "Stage 6", focus: "silent h", phonemes: ["a", "gh", "a", "s", "t"] },
    { word: "ghastly", stage: "Stage 6", focus: "silent h", phonemes: ["gh", "a", "s", "t", "l", "y"] },
    { word: "spaghetti", stage: "Stage 6", focus: "silent h", phonemes: ["s", "p", "a", "gh", "e", "tt", "i"] },
    { word: "gingham", stage: "Stage 6", focus: "silent h", phonemes: ["g", "i", "n", "gh", "a", "m"] },
    { word: "ghoul", stage: "Stage 6", focus: "silent h", phonemes: ["gh", "ou", "l"] },
    { word: "ghetto", stage: "Stage 6", focus: "silent h", phonemes: ["gh", "e", "tt", "o"] },
    { word: "yoghurt", stage: "Stage 6", focus: "silent h", phonemes: ["y", "o", "gh", "ur", "t"] },
    { word: "submarine", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "m", "ar", "ine"] },
    { word: "subject", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "j", "e", "c", "t"] },
    { word: "subway", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "w", "ay"] },
    { word: "subdue", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "d", "ue"] },
    //{ word: "subtract", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "t", "r", "a", "c", "t"] },
    //{ word: "substitute", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "s", "t", "i", "t", "ute"] },
    { word: "subvert", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "v", "er", "t"] },
    { word: "submerge", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "m", "er", "ge"] },
    { word: "suburb", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "ur", "b"] },
    { word: "subsist", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "s", "i", "s", "t"] },
    //{ word: "subterranean", stage: "Stage 6", focus: "Prefix - Sub", phonemes: ["s", "u", "b", "t", "er", "ra", "n", "ea", "n"] },
    { word: "hemisphere", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["h", "e", "m", "i", "s", "ph", "ere"] },
    //{ word: "semicircle", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "c", "ir", "c", "le"] },
    //{ word: "hemiplegic", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["h", "e", "m", "i", "p", "l", "e", "g", "i", "c"] },
    //{ word: "semicolon", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "c", "o", "l", "o", "n"] },
    //{ word: "semiconscious", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "c", "o", "n", "sc", "i", "ous"] },
    //{ word: "semipermanent", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "p", "er", "m", "a", "n", "e", "n", "t"] },
    //{ word: "semitransparent", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "t", "r", "a", "n", "s", "p", "ar", "e", "n", "t"] },
    //{ word: "semifinal", stage: "Stage 6", focus: "Prefix - hemi/semi", phonemes: ["s", "e", "m", "i", "f", "i", "n", "a", "l"] },
    { word: "coniferous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["c", "o", "n", "i", "f", "er", "ous"] },
    { word: "vigorous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["v", "i", "g", "or", "ous"] },
    { word: "momentous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["m", "o", "m", "e", "n", "t", "ous"] },
    { word: "nervous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["n", "er", "v", "ous"] },
    { word: "mischievous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["m", "i", "s", "ch", "ie", "v", "ous"] },
    { word: "hazardous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["h", "a", "z", "ar", "d", "ous"] },
    //{ word: "meticulous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["m", "e", "t", "i", "c", "u", "l", "ous"] },
    { word: "nefarious", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["n", "e", "f", "ar", "i", "ous"] },
    //{ word: "contagious", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["c", "o", "n", "t", "a", "g", "i", "ous"] },
    { word: "prosperous", stage: "Stage 6", focus: "Suffix - ous", phonemes: ["p", "r", "o", "s", "p", "er", "ous"] },
    { word: "drastic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["d", "r", "a", "s", "t", "ic"] },
    { word: "volcanic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["v", "o", "l", "c", "a", "n", "ic"] },
    //{ word: "atmospheric", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["a", "t", "m", "o", "s", "ph", "er", "ic"] },
    //{ word: "fantastic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["f", "a", "n", "t", "a", "s", "t", "ic"] },
    { word: "allergic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["a", "ll", "er", "g", "ic"] },
    { word: "fanatic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["f", "a", "n", "a", "t", "ic"] },
    { word: "magnetic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["m", "a", "g", "n", "e", "t", "ic"] },
    //{ word: "sympathetic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["s", "y", "m", "p", "a", "th", "e", "t", "ic"] },
    //{ word: "strategic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["s", "t", "r", "a", "t", "e", "g", "ic"] },
    { word: "gigantic", stage: "Stage 6", focus: "Suffix - ic", phonemes: ["g", "i", "g", "an", "t", "ic"] },
    { word: "aquatic", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "a", "t", "i", "c"] },
    { word: "aquanaut", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "a", "n", "au", "t"] },
    { word: "aquarium", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "ar", "i", "um"] },
    { word: "aqueous", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "e", "ous"] },
    { word: "aquamarine", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "a", "m", "ar", "ine"] },
    { word: "aqualung", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "a", "l", "u", "ng"] },
    //{ word: "semiaquatic", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["s", "e", "m", "i", "a", "qu", "a", "t", "ic"] },
    //{ word: "subaquatic", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["s", "u", "b", "a", "qu", "a", "t", "ic"] },
    { word: "aquaplane", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "a", "p", "l", "a[n]e"] },
    { word: "aqueduct", stage: "Stage 6", focus: "Latin Root Word - aqua, aque", phonemes: ["a", "qu", "e", "d", "u", "c", "t"] },
    { word: "stamp", stage: "Review", focus: "1a", phonemes: ["s", "t", "a", "m", "p"] },
    { word: "wind", stage: "Review", focus: "1a", phonemes: ["w", "i", "n", "d"] },
    { word: "jump", stage: "Review", focus: "1a", phonemes: ["j", "u", "m", "p"] },
    { word: "dust", stage: "Review", focus: "1a", phonemes: ["d", "u", "s", "t"] },
    { word: "kept", stage: "Review", focus: "1a", phonemes: ["k", "e", "p", "t"] },
    { word: "send", stage: "Review", focus: "1a", phonemes: ["s", "e", "n", "d"] },
    { word: "band", stage: "Review", focus: "1b", phonemes: ["b", "a", "n", "d"] },
    { word: "soft", stage: "Review", focus: "1b", phonemes: ["s", "o", "f", "t"] },
    { word: "musk", stage: "Review", focus: "1b", phonemes: ["m", "u", "s", "k"] },
    { word: "plump", stage: "Review", focus: "1b", phonemes: ["p", "l", "u", "m", "p"] },
    { word: "quest", stage: "Review", focus: "1b", phonemes: ["qu", "e", "s", "t"] },
    { word: "theft", stage: "Review", focus: "1b", phonemes: ["th", "e", "f", "t"] },
    { word: "boost", stage: "Review", focus: "1c", phonemes: ["b", "oo", "s", "t"] },
    { word: "plank", stage: "Review", focus: "1c", phonemes: ["p", "l", "s", "n", "k"] },
    { word: "chomp", stage: "Review", focus: "1c", phonemes: ["ch", "o", "m", "p"] },
    { word: "melt", stage: "Review", focus: "1c", phonemes: ["m", "e", "l", "t"] },
    { word: "thump", stage: "Review", focus: "1c", phonemes: ["th", "u", "m", "p"] },
    { word: "bolt", stage: "Review", focus: "1c", phonemes: ["b", "o", "l", "t"] },
    { word: "told", stage: "Review", focus: "1d", phonemes: ["t", "o", "l", "d"] },
    { word: "best", stage: "Review", focus: "1d", phonemes: ["b", "e", "s", "t"] },
    { word: "went", stage: "Review", focus: "1d", phonemes: ["w", "e", "n", "t"] },
    { word: "shelf", stage: "Review", focus: "1d", phonemes: ["sh", "e", "l", "f"] },
    { word: "rant", stage: "Review", focus: "1d", phonemes: ["r", "a", "n", "t"] },
    { word: "chunk", stage: "Review", focus: "1d", phonemes: ["ch", "u", "n", "k"] },
    { word: "stomp", stage: "Review", focus: "1e", phonemes: ["s", "t", "o", "m", "p"] },
    { word: "blink", stage: "Review", focus: "1e", phonemes: ["b", "l", "i", "n", "k"] },
    { word: "swift", stage: "Review", focus: "1e", phonemes: ["s", "w", "i", "f", "t"] },
    { word: "blend", stage: "Review", focus: "1e", phonemes: ["b", "l", "e", "n", "d"] },
    { word: "print", stage: "Review", focus: "1e", phonemes: ["p", "r", "i", "n", "t"] },
    { word: "plant", stage: "Review", focus: "1e", phonemes: ["p", "l", "a", "n", "t"] },
    { word: "gift", stage: "Review", focus: "2a", phonemes: ["g", "i", "f", "t"] },
    { word: "lisp", stage: "Review", focus: "2a", phonemes: ["l", "i", "s", "p"] },
    { word: "sank", stage: "Review", focus: "2a", phonemes: ["s", "a", "n", "k"] },
    { word: "limp", stage: "Review", focus: "2a", phonemes: ["l", "i", "m", "p"] },
    { word: "sent", stage: "Review", focus: "2a", phonemes: ["s", "e", "n", "t"] },
    { word: "pulp", stage: "Review", focus: "2a", phonemes: ["p", "u", "l", "p"] },
    { word: "lamp", stage: "Review", focus: "2b", phonemes: ["l", "a", "m", "p"] },
    { word: "tusk", stage: "Review", focus: "2b", phonemes: ["t", "u", "s", "k"] },
    { word: "list", stage: "Review", focus: "2b", phonemes: ["l", "i", "s", "t"] },
    { word: "send", stage: "Review", focus: "2b", phonemes: ["s", "e", "n", "d"] },
    { word: "along", stage: "Review", focus: "2b", phonemes: ["a", "l", "o", "ng"] },
    { word: "form", stage: "Review", focus: "2b", phonemes: ["f", "or", "m"] },
    { word: "ever", stage: "Review", focus: "2c", phonemes: ["e", "v", "er"] },
    { word: "away", stage: "Review", focus: "2c", phonemes: ["a", "w", "ay"] },
    { word: "garden", stage: "Review", focus: "2c", phonemes: ["g", "ar", "d", "e", "n"] },
    { word: "drink", stage: "Review", focus: "2c", phonemes: ["d", "r", "i", "n", "k"] },
    { word: "small", stage: "Review", focus: "2c", phonemes: ["s", "m", "all"] },
    { word: "point", stage: "Review", focus: "2c", phonemes: ["p", "oi", "n", "t"] },
    { word: "morning", stage: "Review", focus: "2d", phonemes: ["m", "or", "n", "ing"] },
    { word: "number", stage: "Review", focus: "2d", phonemes: ["n", "u", "m", "b", "er"] },
    { word: "seven", stage: "Review", focus: "2d", phonemes: ["s", "e", "v", "e", "n"] },
    { word: "today", stage: "Review", focus: "2d", phonemes: ["t", "o", "d", "ay"] },
    { word: "king", stage: "Review", focus: "2d", phonemes: ["k", "ing"] },
    { word: "start", stage: "Review", focus: "2d", phonemes: ["s", "t", "ar", "t"] },
    { word: "said", stage: "Review", focus: "2e", phonemes: ["s", "ai", "d"] },
    { word: "farm", stage: "Review", focus: "2e", phonemes: ["f", "ar", "m"] },
    { word: "sister", stage: "Review", focus: "2e", phonemes: ["s", "i", "s", "t", "er"] },
    { word: "bring", stage: "Review", focus: "2e", phonemes: ["b", "r", "ing"] },
    { word: "horse", stage: "Review", focus: "2e", phonemes: ["h", "or", "se"] },
    { word: "stall", stage: "Review", focus: "2e", phonemes: ["s", "t", "all"] },
    { word: "skip", stage: "Review", focus: "3a", phonemes: ["s", "k", "i", "p"] },
    { word: "faint", stage: "Review", focus: "3a", phonemes: ["f", "ai", "n", "t"] },
    { word: "thirty", stage: "Review", focus: "3a", phonemes: ["th", "ir", "t", "y"] },
    { word: "enjoy", stage: "Review", focus: "3a", phonemes: ["e", "n", "j", "oy"] },
    { word: "spark", stage: "Review", focus: "3a", phonemes: ["s", "p", "ar", "k"] },
    { word: "noise", stage: "Review", focus: "3a", phonemes: ["n", "oi", "se"] },
    { word: "shrunk", stage: "Review", focus: "3b", phonemes: ["sh", "r", "u", "n", "k"] },
    { word: "pillow", stage: "Review", focus: "3b", phonemes: ["p", "i", "ll", "ow"] },
    { word: "dusty", stage: "Review", focus: "3b", phonemes: ["d", "u", "s", "t", "y"] },
    { word: "clue", stage: "Review", focus: "3b", phonemes: ["c", "l", "ue"] },
    { word: "under", stage: "Review", focus: "3b", phonemes: ["u", "n", "d", "er"] },
    { word: "splint", stage: "Review", focus: "3b", phonemes: ["s", "p", "l", "i", "n", "t"] },
    { word: "never", stage: "Review", focus: "3c", phonemes: ["n", "e", "v", "er"] },
    { word: "flower", stage: "Review", focus: "3c", phonemes: ["f", "l", "ow", "er"] },
    { word: "blew", stage: "Review", focus: "3c", phonemes: ["b", "l", "ew"] },
    { word: "knife", stage: "Review", focus: "3c", phonemes: ["kn", "i[f]e"] },
    { word: "scorch", stage: "Review", focus: "3c", phonemes: ["s", "c", "or", "ch"] },
    { word: "nurse", stage: "Review", focus: "3c", phonemes: ["n", "ur", "se"] },
    { word: "actor", stage: "Review", focus: "3d", phonemes: ["a", "c", "t", "or"] },
    { word: "replace", stage: "Review", focus: "3d", phonemes: ["r", "e", "p", "l", "a[c]e"] },
    { word: "quench", stage: "Review", focus: "3d", phonemes: ["qu", "e", "n", "ch"] },
    { word: "doubt", stage: "Review", focus: "3d", phonemes: ["d", "ou", "bt"] },
    { word: "tasty", stage: "Review", focus: "3d", phonemes: ["t", "a", "s", "t", "y"] },
    { word: "parent", stage: "Review", focus: "3d", phonemes: ["p", "ar", "e", "n", "t"] },
    { word: "kidney", stage: "Review", focus: "3e", phonemes: ["k", "i", "d", "n", "ey"] },
    { word: "cupboard", stage: "Review", focus: "3e", phonemes: ["c", "u", "p", "b", "oar", "d"] },
    { word: "breakfast", stage: "Review", focus: "3e", phonemes: ["b", "r", "ea", "k", "f", "a", "s", "t"] },
    { word: "frighten", stage: "Review", focus: "3e", phonemes: ["f", "r", "igh", "t", "e", "n"] },
    { word: "ignore", stage: "Review", focus: "3e", phonemes: ["ig", "n", "ore"] },
    { word: "scratch", stage: "Review", focus: "3e", phonemes: ["s", "c", "r", "a", "tch"] },
    { word: "boat", stage: "Review", focus: "4a", phonemes: ["b", "oa", "t"] },
    { word: "window", stage: "Review", focus: "4a", phonemes: ["w", "i", "n", "d", "ow"] },
    { word: "still", stage: "Review", focus: "4a", phonemes: ["s", "t", "i", "ll"] },
    { word: "added", stage: "Review", focus: "4a", phonemes: ["a", "dd", "ed"] },
    { word: "across", stage: "Review", focus: "4a", phonemes: ["a", "cc", "r", "o", "ss"] },
    { word: "eggs", stage: "Review", focus: "4a", phonemes: ["e", "gg", "s"] },
    { word: "spell", stage: "Review", focus: "4b", phonemes: ["s", "p", "e", "ll"] },
    { word: "between", stage: "Review", focus: "4b", phonemes: ["b", "e", "t", "w", "ee", "n"] },
    { word: "really", stage: "Review", focus: "4b", phonemes: ["r", "ea", "l", "ly"] },
    { word: "bike", stage: "Review", focus: "4b", phonemes: ["b", "i[k]e"] },
    { word: "broke", stage: "Review", focus: "4b", phonemes: ["b", "r", "o[k]e"] },
    { word: "asleep", stage: "Review", focus: "4b", phonemes: ["a", "s", "l", "ee", "p"] },
    { word: "teacher", stage: "Review", focus: "4c", phonemes: ["t", "ea", "ch", "er"] },
    { word: "even", stage: "Review", focus: "4c", phonemes: ["e[v]e", "n"] },
    { word: "inside", stage: "Review", focus: "4c", phonemes: ["i", "n", "s", "i[d]e"] },
    { word: "something", stage: "Review", focus: "4c", phonemes: ["s", "o", "me", "th", "i", "ng"] },
    { word: "dress", stage: "Review", focus: "4c", phonemes: ["d", "r", "e", "ss"] },
    { word: "weekend", stage: "Review", focus: "4c", phonemes: ["w", "ee", "k", "e", "n", "d"] },
    { word: "bluff", stage: "Review", focus: "4d", phonemes: ["b", "l", "u", "ff"] },
    { word: "fuzzy", stage: "Review", focus: "4d", phonemes: ["r", "u", "zz", "y"] },
    { word: "scold", stage: "Review", focus: "4d", phonemes: ["s", "c", "o", "l", "d"] },
    { word: "squeaked", stage: "Review", focus: "4d", phonemes: ["s", "qu", "ea", "k", "ed"] },
    { word: "first", stage: "Review", focus: "4d", phonemes: ["f", "ir", "s", "t"] },
    { word: "about", stage: "Review", focus: "4d", phonemes: ["a", "b", "ou", "t"] },
    { word: "kind", stage: "Review", focus: "4e", phonemes: ["k", "i", "n", "d"] },
    { word: "paper", stage: "Review", focus: "4e", phonemes: ["p", "a", "p", "er"] },
    { word: "flew", stage: "Review", focus: "4e", phonemes: ["f", "l", "ew"] },
    { word: "study", stage: "Review", focus: "4e", phonemes: ["s", "t", "u", "d", "y"] },
    { word: "hurt", stage: "Review", focus: "4e", phonemes: ["h", "ur", "t"] },
    { word: "person", stage: "Review", focus: "4e", phonemes: ["p", "er", "s", "o", "n"] },
    { word: "leave", stage: "Review", focus: "5a", phonemes: ["l", "ea", "ve"] },
    { word: "threw", stage: "Review", focus: "5a", phonemes: ["th", "r", "ew"] },
    { word: "above", stage: "Review", focus: "5a", phonemes: ["a", "b", "o", "ve"] },
    { word: "letter", stage: "Review", focus: "5a", phonemes: ["l", "e", "tt", "er"] },
    { word: "house", stage: "Review", focus: "5a", phonemes: ["h", "ou", "se"] },
    { word: "quickly", stage: "Review", focus: "5a", phonemes: ["qu", "i", "ck", "l", "y"] },
    { word: "month", stage: "Review", focus: "5b", phonemes: ["m", "o", "n", "th"] },
    { word: "open", stage: "Review", focus: "5b", phonemes: ["o", "p", "e", "n"] },
    { word: "give", stage: "Review", focus: "5b", phonemes: ["g", "i[v]e"] },
    { word: "know", stage: "Review", focus: "5b", phonemes: ["kn", "ow"] },
    { word: "twenty", stage: "Review", focus: "5b", phonemes: ["t", "w", "e", "n", "t", "y"] },
    { word: "sound", stage: "Review", focus: "5b", phonemes: ["s", "ou", "n", "d"] },
    { word: "together", stage: "Review", focus: "5c", phonemes: ["t", "o", "g", "e", "th", "er"] },
    { word: "without", stage: "Review", focus: "5c", phonemes: ["w", "i", "th", "ou", "t"] },
    { word: "buddy", stage: "Review", focus: "5c", phonemes: ["b", "u", "dd", "y"] },
    { word: "brown", stage: "Review", focus: "5c", phonemes: ["b", "r", "ow", "n"] },
    { word: "different", stage: "Review", focus: "5c", phonemes: ["d", "i", "ff", "er", "e", "n", "t"] },
    { word: "another", stage: "Review", focus: "5c", phonemes: ["a", "n", "o", "th", "er"] },
    { word: "animal", stage: "Review", focus: "5d", phonemes: ["a", "n", "i", "m", "al"] },
    { word: "leaf", stage: "Review", focus: "5d", phonemes: ["l", "ea", "ff"] },
    { word: "flew", stage: "Review", focus: "5d", phonemes: ["f", "l", "ew"] },
    { word: "work", stage: "Review", focus: "5d", phonemes: ["w", "or", "k"] },
    { word: "monster", stage: "Review", focus: "5d", phonemes: ["m", "o", "n", "s", "t", "er"] },
    { word: "awry", stage: "Review", focus: "5d", phonemes: ["a", "wr", "y"] },
    { word: "mouse", stage: "Review", focus: "5e", phonemes: ["m", "ou", "se"] },
    { word: "crying", stage: "Review", focus: "5e", phonemes: ["c", "r", "y", "ing"] },
    { word: "find", stage: "Review", focus: "5e", phonemes: ["f", "i", "n", "d"] },
    { word: "other", stage: "Review", focus: "5e", phonemes: ["o", "th", "er"] },
    { word: "draw", stage: "Review", focus: "5e", phonemes: ["d", "r", "aw"] },
    { word: "round", stage: "Review", focus: "5e", phonemes: ["r", "ou", "n", "d"] },
    { word: "jumped", stage: "Review", focus: "6a", phonemes: ["j", "u", "m", "p", "ed"] },
    { word: "thanked", stage: "Review", focus: "6a", phonemes: ["th", "a", "n", "k", "ed"] },
    { word: "cooked", stage: "Review", focus: "6a", phonemes: ["c", "oo", "k", "ed"] },
    { word: "shouted", stage: "Review", focus: "6a", phonemes: ["sh", "ou", "t", "ed"] },
    { word: "shared", stage: "Review", focus: "6a", phonemes: ["sh", "ar", "ed"] },
    { word: "started", stage: "Review", focus: "6a", phonemes: ["s", "t", "ar", "t", "ed"] },
    { word: "flipped", stage: "Review", focus: "6b", phonemes: ["f", "l", "i", "pp", "ed"] },
    { word: "strapped", stage: "Review", focus: "6b", phonemes: ["s", "t", "r", "a", "pp", "ed"] },
    { word: "hugged", stage: "Review", focus: "6b", phonemes: ["h", "u", "gg", "ed"] },
    { word: "skipped", stage: "Review", focus: "6b", phonemes: ["s", "k", "i", "pp", "ed"] },
    { word: "dressed", stage: "Review", focus: "6b", phonemes: ["d", "r", "e", "ss", "ed"] },
    { word: "strapped", stage: "Review", focus: "6b", phonemes: ["s", "t", "r", "pp", "ed"] },
    { word: "named", stage: "Review", focus: "6c", phonemes: ["n", "a[m]e", "d"] },
    { word: "shaved", stage: "Review", focus: "6c", phonemes: ["sh", "a[v]e", "d"] },
    { word: "lived", stage: "Review", focus: "6c", phonemes: ["l", "i[v]e", "d"] },
    { word: "prided", stage: "Review", focus: "6c", phonemes: ["p", "r", "i[d]e", "d"] },
    { word: "baked", stage: "Review", focus: "6c", phonemes: ["b", "a[k]e", "d"] },
    { word: "deleted", stage: "Review", focus: "6c", phonemes: ["d", "e", "l", "e[t]e", "d"] },
    { word: "tried", stage: "Review", focus: "6d", phonemes: ["t", "r", "ied"] },
    { word: "cried", stage: "Review", focus: "6d", phonemes: ["c", "r", "ied"] },
    { word: "dried", stage: "Review", focus: "6d", phonemes: ["d", "r", "ied"] },
    { word: "spied", stage: "Review", focus: "6d", phonemes: ["s", "p", "ied"] },
    { word: "worried", stage: "Review", focus: "6d", phonemes: ["w", "o", "rr", "ied"] },
    { word: "died", stage: "Review", focus: "6d", phonemes: ["d", "ie", "d"] },
    { word: "melted", stage: "Review", focus: "6e", phonemes: ["m", "e", "l", "t", "ed"] },
    { word: "painted", stage: "Review", focus: "6e", phonemes: ["p", "ai", "n", "t", "ed"] },
    { word: "pointed", stage: "Review", focus: "6e", phonemes: ["p", "oi", "n", "t", "ed"] },
    { word: "planted", stage: "Review", focus: "6e", phonemes: ["p", "l", "a", "n", "t", "ed"] },
    { word: "admitted", stage: "Review", focus: "6e", phonemes: ["a", "d", "m", "i", "tt", "ed"] },
    { word: "ranted", stage: "Review", focus: "6e", phonemes: ["r", "a", "n", "t", "ed"] },
    { word: "quickly", stage: "Review", focus: "7a", phonemes: ["qu", "i", "ck", "ly"] },
    { word: "sweetly", stage: "Review", focus: "7a", phonemes: ["s", "w", "ee", "t", "ly"] },
    { word: "slowly", stage: "Review", focus: "7a", phonemes: ["s", "l", "ow", "ly"] },
    { word: "kindly", stage: "Review", focus: "7a", phonemes: ["k", "i", "n", "d", "ly"] },
    { word: "neatly", stage: "Review", focus: "7a", phonemes: ["n", "ea", "t", "ly"] },
    { word: "poorly", stage: "Review", focus: "7a", phonemes: ["p", "oo", "r", "ly"] },
    { word: "lovely", stage: "Review", focus: "7b", phonemes: ["l", "o", "ve", "ly"] },
    { word: "safely", stage: "Review", focus: "7b", phonemes: ["s", "a[f]e", "ly"] },
    { word: "likely", stage: "Review", focus: "7b", phonemes: ["l", "i[k]e", "ly"] },
    { word: "lonely", stage: "Review", focus: "7b", phonemes: ["l", "o[n]e", "ly"] },
    { word: "lately", stage: "Review", focus: "7b", phonemes: ["l", "a[t]e", "ly"] },
    { word: "wisely", stage: "Review", focus: "7b", phonemes: ["w", "i[s]e", "ly"] },
    { word: "eating", stage: "Review", focus: "7c", phonemes: ["ea", "t", "ing"] },
    { word: "lifting", stage: "Review", focus: "7c", phonemes: ["l", "i", "f", "t", "ing"] },
    { word: "destroying", stage: "Review", focus: "7c", phonemes: ["d", "e", "s", "t", "r", "oy", "ing"] },
    { word: "holding", stage: "Review", focus: "7c", phonemes: ["h", "o", "l", "d", "ing"] },
    { word: "training", stage: "Review", focus: "7c", phonemes: ["t", "r", "ai", "n", "ing"] },
    { word: "thinking", stage: "Review", focus: "7c", phonemes: ["th", "i", "n", "k", "ing"] },
    { word: "shopping", stage: "Review", focus: "7d", phonemes: ["sh", "o", "pp", "ing"] },
    { word: "scrubbing", stage: "Review", focus: "7d", phonemes: ["s", "c", "r", "u", "bb", "ing"] },
    { word: "skipping", stage: "Review", focus: "7d", phonemes: ["s", "k", "i", "pp", "ing"] },
    { word: "flipping", stage: "Review", focus: "7d", phonemes: ["f", "l", "i", "pp", "ing"] },
    { word: "strumming", stage: "Review", focus: "7d", phonemes: ["s", "t", "r", "u", "mm", "ing"] },
    { word: "patting", stage: "Review", focus: "7d", phonemes: ["p", "a", "tt", "ing"] },
    { word: "having", stage: "Review", focus: "7e", phonemes: ["h", "a", "v", "ing"] },
    { word: "waving", stage: "Review", focus: "7e", phonemes: ["w", "a", "v", "ing"] },
    { word: "sliding", stage: "Review", focus: "7e", phonemes: ["s", "l", "i", "d", "ing"] },
    { word: "smiling", stage: "Review", focus: "7e", phonemes: ["s", "m", "i", "l", "ing"] },
    { word: "joking", stage: "Review", focus: "7e", phonemes: ["j", "o", "k", "ing"] },
    { word: "shaking", stage: "Review", focus: "7e", phonemes: ["sh", "a", "k", "ing"] },
    { word: "teacher", stage: "Review", focus: "8a", phonemes: ["t", "ea", "ch", "er"] },
    { word: "player", stage: "Review", focus: "8a", phonemes: ["p", "l", "ay", "er"] },
    { word: "toaster", stage: "Review", focus: "8a", phonemes: ["t", "oa", "s", "t", "er"] },
    { word: "banker", stage: "Review", focus: "8a", phonemes: ["b", "a", "n", "k", "er"] },
    { word: "speaker", stage: "Review", focus: "8a", phonemes: ["s", "p", "ea", "k", "er"] },
    { word: "painter", stage: "Review", focus: "8a", phonemes: ["p", "ai", "n", "t", "er"] },
    { word: "later", stage: "Review", focus: "8b", phonemes: ["l", "a", "t", "er"] },
    { word: "shaker", stage: "Review", focus: "8b", phonemes: ["sh", "a", "k", "er"] },
    { word: "wiper", stage: "Review", focus: "8b", phonemes: ["w", "i", "p", "er"] },
    { word: "dancer", stage: "Review", focus: "8b", phonemes: ["d", "a", "n", "c", "er"] },
    { word: "maker", stage: "Review", focus: "8b", phonemes: ["m", "a", "k", "er"] },
    { word: "baker", stage: "Review", focus: "8b", phonemes: ["b", "a", "k", "er"] },
    { word: "rocky", stage: "Review", focus: "8c", phonemes: ["r", "o", "ck", "y"] },
    { word: "sticky", stage: "Review", focus: "8c", phonemes: ["s", "t", "i", "ck", "y"] },
    { word: "dirty", stage: "Review", focus: "8c", phonemes: ["d", "ir", "t", "y"] },
    { word: "flashy", stage: "Review", focus: "8c", phonemes: ["f", "l", "a", "sh", "y"] },
    { word: "sleepy", stage: "Review", focus: "8c", phonemes: ["s", "l", "ee", "p", "y"] },
    { word: "bulky", stage: "Review", focus: "8c", phonemes: ["b", "u", "l", "k", "y"] },
    { word: "funny", stage: "Review", focus: "8d", phonemes: ["f", "u", "nn", "y"] },
    { word: "muddy", stage: "Review", focus: "8d", phonemes: ["m", "u", "dd", "y"] },
    { word: "spotty", stage: "Review", focus: "8d", phonemes: ["s", "p", "o", "tt", "y"] },
    { word: "floppy", stage: "Review", focus: "8d", phonemes: ["f", "l", "o", "pp", "y"] },
    { word: "foggy", stage: "Review", focus: "8d", phonemes: ["f", "o", "gg", "y"] },
    { word: "dotty", stage: "Review", focus: "8d", phonemes: ["d", "o", "tt", "y"] },
    { word: "yucky", stage: "Review", focus: "8e", phonemes: ["y", "u", "ck", "y"] },
    { word: "lumpy", stage: "Review", focus: "8e", phonemes: ["l", "u", "m", "p", "y"] },
    { word: "risky", stage: "Review", focus: "8e", phonemes: ["r", "i", "s", "k", "y"] },
    { word: "silky", stage: "Review", focus: "8e", phonemes: ["s", "i", "l", "k", "y"] },
    { word: "greedy", stage: "Review", focus: "8e", phonemes: ["g", "r", "ee", "d", "y"] },
    { word: "windy", stage: "Review", focus: "8e", phonemes: ["w", "i", "n", "d", "y"] },
    { word: "games", stage: "Review", focus: "9a", phonemes: ["g", "a[m]e", "s"] },
    { word: "snails", stage: "Review", focus: "9a", phonemes: ["s", "n", "ai", "l", "s"] },
    { word: "flowers", stage: "Review", focus: "9a", phonemes: ["f", "l", "ow", "er", "s"] },
    { word: "clouds", stage: "Review", focus: "9a", phonemes: ["c", "l", "ou", "d", "s"] },
    { word: "girls", stage: "Review", focus: "9a", phonemes: ["g", "ir", "l", "s"] },
    { word: "names", stage: "Review", focus: "9a", phonemes: ["n", "a[m]e", "s"] },
    { word: "coaches", stage: "Review", focus: "9b", phonemes: ["c", "oa", "ch", "es"] },
    { word: "flashes", stage: "Review", focus: "9b", phonemes: ["f", "l", "a", "sh", "es"] },
    { word: "peaches", stage: "Review", focus: "9b", phonemes: ["p", "ea", "ch", "es"] },
    { word: "beaches", stage: "Review", focus: "9b", phonemes: ["b", "ea", "ch", "es"] },
    { word: "wishes", stage: "Review", focus: "9b", phonemes: ["w", "i", "sh", "es"] },
    { word: "glasses", stage: "Review", focus: "9b", phonemes: ["g", "l", "a", "ss", "es"] },
    { word: "bodies", stage: "Review", focus: "9c", phonemes: ["b", "o", "d", "ies"] },
    { word: "stories", stage: "Review", focus: "9c", phonemes: ["s", "t", "or", "ies"] },
    { word: "babies", stage: "Review", focus: "9c", phonemes: ["b", "a", "b", "ies"] },
    { word: "copies", stage: "Review", focus: "9c", phonemes: ["c", "o", "p", "ies"] },
    { word: "flies", stage: "Review", focus: "9c", phonemes: ["f", "l", "ies"] },
    { word: "ponies", stage: "Review", focus: "9c", phonemes: ["p", "o", "n", "ies"] },
    { word: "leaves", stage: "Review", focus: "9d", phonemes: ["l", "ea", "v", "es"] },
    { word: "loaves", stage: "Review", focus: "9d", phonemes: ["l", "oa", "v", "es"] },
    { word: "knives", stage: "Review", focus: "9d", phonemes: ["kn", "i", "v", "es"] },
    { word: "lives", stage: "Review", focus: "9d", phonemes: ["l", "i", "v", "es"] },
    { word: "shelves", stage: "Review", focus: "9d", phonemes: ["sh", "e", "l", "v", "es"] },
    { word: "elves", stage: "Review", focus: "9d", phonemes: ["e", "l", "v", "es"] },
    { word: "splash", stage: "Review", focus: "9e", phonemes: ["s", "p", "l", "s", "sh"] },
    { word: "season", stage: "Review", focus: "9e", phonemes: ["s", "ea", "s", "on"] },
    { word: "trade", stage: "Review", focus: "9e", phonemes: ["t", "r", "a[d]e"] },
    { word: "return", stage: "Review", focus: "9e", phonemes: ["re", "t", "ur", "n"] },
    { word: "yawn", stage: "Review", focus: "9e", phonemes: ["y", "aw", "n"] },
    { word: "drench", stage: "Review", focus: "9e", phonemes: ["d", "r", "e", "n", "ch"] }
];

export function useStageSet(): string[] {
    return Array.from(new Set(Words.map(definition => definition.stage))).sort();
}

export function useFocusSet(stage: string = "1"): string[] {
    return Array.from(new Set(Words.filter(definition => definition.stage === stage).map(definition => definition.focus))).sort();
}