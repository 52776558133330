import { shuffleArray } from "../Utility";
import { WordDefinition, Words } from "./Words";

export class Dictionary {
    private words: WordDefinition[];

    private indexes: number[];

    constructor(stage: string = "1", focus?: string) {
        this.words = Words.filter(word => word.stage === stage);

        if (focus) {
            this.words = this.words.filter(word => word.focus === focus);
        }

        this.indexes = this.generateRandomIndexes();
    }

    private generateRandomIndexes(): number[] {
        return shuffleArray(Array.from(Array(this.words.length).keys()));
    }

    nextWord(): WordDefinition {
        if (this.indexes.length === 0) {
            this.indexes = this.generateRandomIndexes();
        }

        const index = this.indexes.pop();
        const definition = this.words[index!];

        return definition;
    }
}