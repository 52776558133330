import { FC, PropsWithChildren } from 'react';
import { useDrag } from 'react-dnd';
import { ObjectType } from '../../api';
import './Tool.scss';

export interface ToolProps extends PropsWithChildren {
    type: ObjectType;
}

export const Tool: FC<ToolProps> = ({ children, type }) => {
    // eslint-disable-next-line
    const [_, dragRef] = useDrag(
        () => ({
            type: type,
            canDrag: true
        }),
        []
    );

    return (
        <div className="Tool HoverEffect" ref={dragRef}>
            {children}
        </div>
    )
};
