import { Children, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStageSet } from '../../api';
import './StageMenu.scss';

export interface StageMenuProps { }

export const StageMenu: FC<StageMenuProps> = () => {
    const stageSet = useStageSet();
    const navigate = useNavigate();

    function handleClick(stage: string) {
        navigate(`/${stage}`);
    }

    return (
        <div className="StageMenu">
            <div className="StageTitle">
                <div className="Text">Choose Your Stage</div>
            </div>
            <div className="StageNames">
                {Children.toArray(stageSet.map(stage =>
                    <div className="StageName HoverEffect" onClick={() => handleClick(stage)}>
                        <div className="Text">{stage}</div>
                    </div>
                ))}
            </div>
        </div>
    )
};