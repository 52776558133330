import classNames from 'classnames';
import { FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useRecoilValue } from 'recoil';
import { ObjectType } from '../../api';
import { Outcome, Phoneme, isDigraph, outcomeState, useSetAnswer } from '../../state';
import './AnswerCard.scss';

export interface AnswerCardProps {
    phoneme: Phoneme;
    index: number;
}

export const AnswerCard: FC<AnswerCardProps> = ({ phoneme, index }) => {
    const outcome = useRecoilValue(outcomeState);
    const setAnswer = useSetAnswer();

    const canDrag = phoneme.hasText && outcome !== Outcome.Correct;

    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: ObjectType.AnswerCard,
            item: { phoneme },
            canDrag: canDrag,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult<{ phoneme: Phoneme }>();

                if (dropResult) {
                    setAnswer(dropResult.phoneme, index);
                }
            }
        }),
        [phoneme, index, canDrag]
    );

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: [ObjectType.WordCard, ObjectType.AnswerCard],
            canDrop: (item: { phoneme: Phoneme }, monitor) => {
                const type = monitor.getItemType();

                if (type === ObjectType.WordCard) {
                    return !isDigraph(item.phoneme) && !phoneme.hasText;
                }

                return true;
            },
            drop: (item: { phoneme: Phoneme }) => {
                setAnswer(item.phoneme, index);

                return { phoneme };
            },
            collect: (monitor) => {
                const type = monitor.getItemType();
                const item = monitor.getItem();

                const wordCondition = type === ObjectType.WordCard && !isDigraph(item.phoneme) && !phoneme.hasText;
                const answerCondition = type === ObjectType.AnswerCard && item.phoneme !== phoneme;

                return ({
                    isOver: (wordCondition || answerCondition) && !!monitor.isOver()
                });
            }
        }),
        [phoneme, index]
    );

    const cardClassName = classNames('AnswerCard', { IsOver: isOver });
    const textClassName = classNames('Text', { Animation: outcome === Outcome.Correct });
    const textStyle = { "--index": index } as React.CSSProperties;

    return (
        <div ref={dropRef}>
            <div className={cardClassName} ref={dragRef} style={{ opacity, cursor: canDrag ? 'grab' : 'default' }}>
                <div className={textClassName} style={textStyle}>{phoneme.text}</div>
            </div>
        </div>
    )
};
