import classNames from 'classnames';
import { FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSetRecoilState } from 'recoil';
import { ObjectType } from '../../api';
import { Phoneme, PhonemeType, isDigraph, phonemesState } from '../../state';
import './WordCard.scss';

export interface WordCardProps {
    phoneme: Phoneme;
    index: number;
}

export const WordCard: FC<WordCardProps> = ({ phoneme, index }) => {
    const setPhonemes = useSetRecoilState(phonemesState);
    const removePhoneme = (phonemes: Phoneme[]) => phonemes.filter(v => v.type !== phoneme.type);
    const clearPhoneme = () => setPhonemes(current => current.map((v, i) => index === i ? removePhoneme(v) : v));
    const cutPhoneme = () => setPhonemes(current => current.map((v, i) => index === i ? v[0].cut() : v));

    const [{ opacity, isDragging }, dragRef] = useDrag(
        () => ({
            type: ObjectType.WordCard,
            item: { phoneme },
            canDrag: phoneme.hasText,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
                isDragging: monitor.isDragging()
            }),
            end: (_, monitor) => {
                if (monitor.didDrop()) {
                    clearPhoneme();
                }
            }
        }),
        [phoneme]
    );

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: ObjectType.ScissorsTool,
            canDrop: () => isDigraph(phoneme),
            collect: (monitor) => ({
                isOver: !!monitor.isOver()
            }),
            drop: () => cutPhoneme()
        }),
        [phoneme]
    );

    const cardClassName = classNames('WordCard HoverEffect', { IsLeft: phoneme.type === PhonemeType.Left, IsRight: phoneme.type === PhonemeType.Right, IsOver: isOver && isDigraph(phoneme), Hidden: isDragging || !phoneme.hasText });
    const cardStyle = { opacity, cursor: phoneme.hasText ? 'grab' : 'default' };

    return (
        <div ref={dropRef} className='WordCardContainer'>
            <div ref={dragRef} className={cardClassName} style={cardStyle}>
                <div className='Text'>{phoneme.text}</div>
            </div>
        </div>
    );
};