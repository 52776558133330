import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import { Dictionary } from './api';
import { FocusMenu, Game, StageMenu } from './components';
import { allButtonText } from './components/FocusMenu/FocusMenu';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { dictionaryState, useSetDefinition } from './state';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// Used to set the dictionary state when the <Game> component first loads
const GameState = () => {
    const { stage, focus } = useParams();
    const setDictionary = useSetRecoilState(dictionaryState);
    const setDefinition = useSetDefinition();

    useEffect(() => {
        const dictionary = new Dictionary(stage, focus);

        setDictionary(dictionary);
        setDefinition(dictionary.nextWord());
    }, [setDictionary, setDefinition, focus, stage]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Game />
        </DndProvider>
    );
};

const ErrorPage = () => (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
            <span>Nothing to see here!</span>
            <a href='/'>Return to the menu</a>
        </div>
    </div>
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <StageMenu />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:stage",
        element: <FocusMenu />,
        errorElement: <ErrorPage />,
    },
    {
        path: `/:stage/${allButtonText}`,
        element: <GameState />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:stage/:focus",
        element: <GameState />,
        errorElement: <ErrorPage />,
    },
]);

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
